import React, { useCallback, useContext, useEffect, useState } from 'react'
import MeterHeader from '../../components/headers/MeterHeader'
import Notification from '../../components/Notification'
import MeterSidebar from './MeterSidebar'
import { GET_ALL_METERS } from '../../queriesAndMutations/queries/meter.queries'
import { GET_ALL_FARMS } from '../../queriesAndMutations/queries/farm.queries'
import { AppStateContext, InitialAppState } from '../../context/appStateContext'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Meter } from '../../utils/typedefs'
import moment from 'moment-timezone'
import LoadingPage from '../loading/LoadingPage'
import { GET_REALTIME_SAMPLES, GET_DAILY_SAMPLES, GET_TWO_AM_TOTALS, GET_SEVEN_DAILY_SAMPLES, } from '../../queriesAndMutations/queries/samples.queries'
import TwoAMChartCard from './chartCards/TwoAMChartCard'
import RealtimeChartCard from './chartCards/RealtimeChartCard'
import DailyChartCard from './chartCards/DailyChartCard'
import SevenDailyChartCard from './chartCards/SevenDailyChartCard'




const LiveMeters: React.FC = () => {
  const { appState} = useContext<Partial<InitialAppState>>(AppStateContext);
  const [currentlySelectedMeter, setCurrentlySelectedMeter] = useState<Meter>();
  const [errors, setErrors] = useState<string>("");

  const allMeters = useQuery<any>(GET_ALL_METERS);
  const allFarms = useQuery<any>(GET_ALL_FARMS);

  const [getDailySamples, { loading: dailyMeterSamplesLoading, error: dailyMeterSamplesError, data: dailyMeterSamplesData }] = useLazyQuery<any>(GET_DAILY_SAMPLES, {

    pollInterval: 30000 //Polling realtime every 30 seconds to get up to date data.
  });

  const [getSevenDailySamples, { loading: sevenDailyMeterSamplesLoading, error: sevenDailyMeterSamplesError, data: sevenDailyMeterSamplesData }] = useLazyQuery<any>(GET_SEVEN_DAILY_SAMPLES, {
   
    pollInterval: 30000 //Polling realtime every 30 seconds to get up to date data.
  });
  const [getRealtimeSamples, { loading: realtimeMeterSamplesLoading, error: realtimeSamplesError, data: realtimeSamplesData }] = useLazyQuery<any>(GET_REALTIME_SAMPLES, {
    
    pollInterval: 30000 //Polling realtime every 30 seconds to get up to date data.
  });
  const [getTwoAmTotals, { loading: twoAMMeterLoading, error: twoAMMeterError, data: twoAMMeterData }] = useLazyQuery<any>(GET_TWO_AM_TOTALS);


  const make30minIntervalArray = (timeStart: Date, timeEnd: Date | null): Date[] => {
    let arr: Date[] = [];
    const now = timeEnd ? moment(timeEnd).unix() : moment().unix();

    const roundedMinutes = Math.round(moment(timeStart).minute() / 30) * 30;
    const t0 = moment(timeStart).minute(roundedMinutes).seconds(0);

    for (let i = t0; i.unix() < now; i = i.add(30, 'minutes')) {
      arr.push(i.toDate());
    }
    return arr;
  }

  const timeStartVar = moment().subtract(1, 'day').toDate();
  const timeStamps = make30minIntervalArray(timeStartVar, null);

  useEffect(() => {
    //when allMeters.data changes this updates the currentlySelectedMeter to keep lastWebhookReceivedAt in meterHeader current too...
    setCurrentlySelectedMeter(allMeters?.data?.meters.find((meter: Meter)=> meter._id === currentlySelectedMeter?._id))
    
  }, [allMeters.data])
  
  useEffect(() => {
    //Get allMeters and begin polling every 30 seconds...
    allMeters.startPolling(30000); 
    
  }, [])


  const handleMeterSidebarClick = useCallback((meter: Meter) => {

    setErrors("");
    
    if (meter.deviceEUI === "NOTFOUND") { // meterCode is not valid...
      setErrors(`Meter ${meter?.name} has an invalid MeterCode: ${meter?.meterCode}`);
      return;
    }

    setCurrentlySelectedMeter(meter);

    getDailySamples({
      variables: {
        "input": {
          "deviceEUI" :meter.deviceEUI,
           "t0": moment(timeStartVar).toISOString(), //.format("YYYY-MM-DD HH:mm:ss.000Z"),
           "t1": moment().toISOString()
          }
        }
    });

    getSevenDailySamples({
      variables: {
        "deviceEUI": meter.deviceEUI
        }
    });

    getRealtimeSamples({
      variables: {
        "deviceEUI": meter.deviceEUI
        }
    });

    getTwoAmTotals({
      variables: {
        "deviceEUI": meter.deviceEUI
        }
    });


  }, [appState]);

  
  if (allFarms.loading || allMeters.loading) { return <LoadingPage />  }
  
  return (
    <>
      <header>
        <MeterHeader currentlySelectedMeter={errors ? null : currentlySelectedMeter!} allMeters={allMeters} getDeviceSamplesCallback={handleMeterSidebarClick} />
      </header>
      <main>
        <div className="py-12 md:fluid-container">
          {
            (() => {
              if (allFarms?.data?.farms?.length && allMeters?.data?.meters?.length) { //if there are BOTH farms and meters...
                return <div className="grid grid-cols-6 justify-center content-center gap-6 lg:grid-cols-12">
                  {/* <!-- Meter Sidebar --> */}
                  <div className='hidden lg:flex col-span-6 lg:col-span-2 lg:visible'>
                    <MeterSidebar allFarms={allFarms} allMeters={allMeters} getDeviceSamplesCallback={handleMeterSidebarClick} />
                  </div>

                  {/* <!-- Meter Central Primary Panel --> */}
                  <div className='col-span-6 lg:col-span-10'>
                    {(errors && dailyMeterSamplesError) && <Notification
                      notifyType="ERROR"
                      notifyTextStrong={errors}
                      notifyTextBeforeLink={'please visit'}
                      notifyLink={'/settings'}
                      notifyLinkText={'Settings'}
                      notifyTextAfterLink={'to add a valid MeterCode.'}
                    />}
                    <RealtimeChartCard timestamps={timeStamps} isLoading={realtimeMeterSamplesLoading} realtimeMeterError={realtimeSamplesError} realtimeMeterData={(realtimeSamplesError || errors) ? null : realtimeSamplesData?.getRealtimeMeterSamples} />
                    <DailyChartCard timestamps={timeStamps} isLoading={dailyMeterSamplesLoading} dailyMeterError={dailyMeterSamplesError} dailyMeterData={(dailyMeterSamplesError || errors) ? null : dailyMeterSamplesData?.getDailyMeterSamples}  />
                    <SevenDailyChartCard timestamps={timeStamps} isLoading={sevenDailyMeterSamplesLoading} sevenDailyMeterError={sevenDailyMeterSamplesError} sevenDailyMeterData={(sevenDailyMeterSamplesError || errors) ? null : sevenDailyMeterSamplesData?.getSevenDailyMeterSamples}  />
                    <TwoAMChartCard timestamps={timeStamps} isLoading={twoAMMeterLoading} twoAmMeterError={twoAMMeterError} twoAmMeterData={(twoAMMeterError || errors) ? null : twoAMMeterData?.getTwoAMTotals} />
                  </div>
                </div>
              }

              if (!allFarms?.data?.farms?.length) { // if no farms
                if (!allMeters?.data?.meters?.length) { //And no meters...
                  return (
                    <>
                      <Notification
                        notifyType="ERROR"
                        notifyTextStrong={'No Farm currently setup'}
                        notifyTextBeforeLink={'please visit'}
                        notifyLink={'/settings'}
                        notifyLinkText={'Settings'}
                        notifyTextAfterLink={'to configure a Farm.'}
                      />
                      <Notification
                        notifyType="ERROR"
                        notifyTextStrong={'No Meter currently setup'}
                        notifyTextBeforeLink={'please visit'}
                        notifyLink={'/settings'}
                        notifyLinkText={'Settings'}
                        notifyTextAfterLink={'to configure a Meter.'}
                      />
                    </>
                  )
                }
              }
              if (allFarms?.data?.farms?.length) { // if there ARE farms
                if (!allMeters?.data?.meters?.length) { //And no meters...
                  return (
                    <Notification
                      notifyType="ERROR"
                      notifyTextStrong={'No Meter currently setup'}
                      notifyTextBeforeLink={'please visit'}
                      notifyLink={'/settings'}
                      notifyLinkText={'Settings'}
                      notifyTextAfterLink={'to configure a Meter.'}
                    />
                  )
                }
              }

            })()
          }
        </div>
      </main>
    </>
  )
}

export default LiveMeters
