import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import * as Yup from 'yup'
import { Form, Formik, FormikProps } from 'formik'

// Import GraphQL queries and mutations
import { ADD_NEW_FARM } from '../../../queriesAndMutations/mutations/farm.mutations'
import { UPDATE_FARM } from '../../../queriesAndMutations/mutations/farm.mutations'
import { GET_ALL_FARMS } from '../../../queriesAndMutations/queries/farm.queries'
import FormikInput from '../../fields/FormikInput'
import { IMessage } from '../../../globals'

type Props = {
  farmToEditId?: string;
  editMode: boolean;
  formId: string;
  farmName?: string;
  farmSize?: number | string;
  handleClose?: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateResponse: React.Dispatch<React.SetStateAction<IMessage>>;
}

type InitialValues = {
  _id: string;
  farmName: string;
  farmSize: number | string;
}

const initialValues = {
  _id: '',
  farmName: '',
  farmSize: '',
}

const FarmCreateInput: React.FC<Props> = ({ editMode, farmToEditId = '', farmName = '', farmSize = '', formId, handleClose, setUpdateResponse }) => {

  const [addNewFarm, { called: addFarmCalled, loading: addFarmLoading, reset: farmMutationReset }] = useMutation<any>(ADD_NEW_FARM);
  const [updateFarm] = useMutation<any>(UPDATE_FARM);
  const allFarms = useQuery<any>(GET_ALL_FARMS);


  //state to handle edited values
  const [valuesToEdit, setValuesToEdit] = useState<InitialValues>(initialValues);

  const validationSchema = Yup.object({
    farmName: Yup.string().required('Farm Name Required'),
    farmSize: Yup.mixed()
    .required('Farm Size is required')
    .test('type', "Must be a number", (value :any) => !isNaN(value))
    .test('farmSize', "Must not exceed 2 decimal places",(value: any) => /^\d*.?\d{0,2}$/.test(value)),
     
  });

  const onSubmit = (values: any) => {
    if (editMode) {
      if (values.farmSize !== farmSize || values.farmName !== farmName) {   //If values have't changed on edit - don't mutate via API
        updateFarm({
          variables: {
            _id: values._id,
            name: values.farmName,
            size: parseFloat(values.farmSize)
          }
        }).then((response: any) => {
          if (response.data) {
            setUpdateResponse && setUpdateResponse({ text: "Farm updated successfully!", colour: "green" });
            
          }
        })
          .catch((err: any) => {
            setUpdateResponse && setUpdateResponse({ text: err.message, colour: "red" });
            
          });;;
      }
    } else {

      setUpdateResponse && setUpdateResponse({ text: "Adding New Farm. Please wait...", colour: "#2b679b" });

      addNewFarm({
        variables: {
          name: values.farmName,
          size: parseFloat(values.farmSize)
        },

        update(cache, { data }) {

          if (!data.farmCreate) { return };

          const farmsQuery: any = cache.readQuery({ query: GET_ALL_FARMS });
          cache.writeQuery({
            query: GET_ALL_FARMS,
            data: { farms: [...farmsQuery.farms, data.farmCreate] }
          });

        }
      }).then((response: any) => {
        if (response.data) {
          setUpdateResponse && setUpdateResponse({ text: "Farm added successfully!", colour: "green" });
          
        }
      })
        .catch((err: any) => {
          setUpdateResponse && setUpdateResponse({ text: err.message, colour: "red" });
          
        });;;
    }

    //Close modal from passed down setState prop
    handleClose && handleClose(false);
  }

  const handleFormChange = (e: any) => {
    setValuesToEdit((prev) => {
      return { ...prev, [e.id]: e.value }
    })
  }

  useEffect(() => {
    editMode && setValuesToEdit({
      _id: farmToEditId,
      farmName: farmName,
      farmSize: farmSize,
    })
  }, [farmSize])

  useEffect(() => {
    if (!addFarmLoading && addFarmCalled) {
      setValuesToEdit(initialValues);
      farmMutationReset();
    }
  }, [addFarmCalled, addFarmLoading])


  return (
    <Formik
      initialValues={valuesToEdit}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount

    >
      {(formik: FormikProps<any>) => {
        return allFarms.loading ? null : (
          <>
            <div className="w-full my-12 card divide-primary/10">
              <div className="p-4 px-6 card-topper ">
                <h3 className="text-xl font-bold text-primary">
                  {editMode ? 'Save Farm' : 'Add New Farm'}
                </h3>
              </div>
              <Form id={formId} onChange={(e) => handleFormChange(e.target)}>
                <div className="card-content">
                  <div className="flex flex-col flex-wrap p-4 px-6 space-y-4 md:space-x-8 md:flex-row md:space-y-0">
                    <FormikInput
                      label="Farm Name"
                      name="farmName"
                      onLoadValue={valuesToEdit.farmName}
                      isLoading={allFarms?.loading || allFarms?.error ? true : false}
                    />
                    <FormikInput
                      label="Farm Size"
                      name="farmSize"
                      unit="ha"
                      onLoadValue={valuesToEdit.farmSize.toString()}
                      isLoading={allFarms?.loading || allFarms?.error ? true : false}
                    />
                  </div>
                </div>
              </Form>
              <div className="card-footer">
                <div className="p-4 px-6">
                  <button
                    form={formId}
                    className="button-base disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray " type="submit"
                    disabled={!formik.isValid}
                  >
                    {editMode ? 'Save Farm' : 'Add New Farm'}
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      }}
    </Formik>
  )
}

export default FarmCreateInput
