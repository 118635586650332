import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import * as Yup from 'yup'
import { Form, Formik, FormikProps } from 'formik'

// Import GraphQL queries and mutations
import { USER_SIGN_UP } from '../../../queriesAndMutations/mutations/user.mutations'
import FormikInput from '../../fields/FormikInput'
import { Link } from 'react-router-dom'
import SignupConfirmation from '../../../pages/signup/SignupConfirmation'


type Props = {
  formId: string;
}

type InitialValues = {
  _id: string;
  name: string;
  email: string;
  password: string,
  passwordAgain: string
}

const initialValues = {
  _id: '',
  name: '',
  email: '',
  password: '',
  passwordAgain: ''
}

const UserSignupInput: React.FC<Props> = ({ formId }) => {

  const [isSignedUp, setIsSignedUp] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [signupErrors, setSignupErrors] = useState<string>("");
  const [signupUser,] = useMutation<any>(USER_SIGN_UP);

  const validationSchema = Yup.object({
    email: Yup.string().required('Email required').email('email address not valid'),
    name: Yup.string().required('Your name is Required'),
    password: Yup.string().required('Password Required').min(6, "Password must be at least 6 characters long")
    .max(50, "Password must be less than 50 characters long"),
    passwordAgain: Yup.string().required('Password Confirmation Required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')  

  });


  const onSubmit = async (values: any) => {
    setIsSubmitting(true);

    await signupUser({
      variables: {
        _id: values._id,
        name: values.name,
        email: values.email.toLowerCase(),
        password: values.password,
        passwordAgain: values.passwordAgain
      }
    }).then((response: any) => {
      // console.log(response);    //For debugging
      if(response.data.userSignup){
        setIsSignedUp(true);
        setIsSubmitting(false);
      }
    })
      .catch((err: any) => {
        setSignupErrors(err.message);
        setIsSubmitting(false);
      });
  }

  return (
    isSignedUp ? <SignupConfirmation /> :
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(formik: FormikProps<any>) => {
          return (
            <>
              <Form className="relative" id={formId} >
                <img
                  src="/assets/bg.jpg"
                  className="absolute inset-0 object-cover w-full h-full saturate-0"
                  alt='background'
                />
                <div className="absolute inset-0 block w-full h-full bg-primary/80"></div>

                <div className="relative z-10 flex flex-col items-center justify-center min-h-screen mx-auto">
                  <div className="divide-y-2 w-80 card divide-primary/20 rounded-2xl bg-shadow-2xl">
                    <div className="bg-primary">
                      <img
                        src="/assets/logo-primary.svg"
                        className="w-3/4 p-6 mx-auto"
                        alt='logo'
                      />
                    </div>
                    <div className="bg-secondary">
                      <h1 className="p-3 text-2xl font-bold text-center text-white">
                        Account Signup
                      </h1>
                    </div>
                    <div className="flex flex-col p-6 space-y-6">
                      <FormikInput
                        label="Your Name"
                        name="name"
                        isLoading={false}
                      />
                      <FormikInput
                        label="Email"
                        name="email"
                        isLoading={false}
                      />
                      <FormikInput
                        label="Password"
                        name="password"
                        isLoading={false}
                        isPassword
                      />
                      <FormikInput
                        label="New Password"
                        name="passwordAgain"
                        isLoading={false}
                        isPassword
                      />
                    </div>
                    <div className="p-6 py-4">
                    <div className="errors mb-6 text-red-700">{signupErrors && <h1>{signupErrors}</h1>}</div>
                      <button type='submit' disabled={isSubmitting} className="w-full button-base disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray">{isSubmitting ? "Please wait..." : "Signup"}</button>
                    </div>
                  </div>
                  <Link className="p-3 text-white/50 hover:text-accent" to="/login">
                    Already registered? / Login here
                  </Link>
                  <Link className="p-3 text-white/50 hover:text-accent" to="/reset-password">
                  Forgot Login / Password?
                </Link>
                </div>
              </Form>
            </>
          )
        }}
      </Formik>
  )
}

export default UserSignupInput
