
import NormalHeader from '../../components/headers/NormalHeader'


const ResetPasswordConfirmation = () => {
  return (
    <div>
        <NormalHeader title={'Password reset confirmation'} />
    <div className="flex flex-col items-center justify-between mt-8 px-4 pb-2 md:px-0">
    <h2 className="text-xl font-bold text-primary mb-4">Password reset Email sent:</h2>
    
  <div className="card">
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="inline-flex min-w-full">
          <div className="w-full overflow-hidden p-4">
          <p>Password reset instructions have been sent to your email.</p>
            <p>Please check your email inbox (and spam folder) for the password reset email.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
  )
}

export default ResetPasswordConfirmation