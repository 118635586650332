import { useMutation } from '@apollo/client'
import { PASSWORD_RESET} from '../queriesAndMutations/mutations/user.mutations'


export const usePasswordReset = () => {

    const [passwordReset] = useMutation<any>(PASSWORD_RESET,{
        onCompleted: (response: any) => {
            console.log(response.userResetLogin)
            return response.userResetLogin;
        },
        onError(error: any) {
          return error.message;
        }
      });


    const resetPassword = async (email: string) => {
        return await passwordReset({variables:{email}}); 
        
    }

    return {resetPassword}
}


