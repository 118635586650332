export enum AuthActionType {
    LOGIN,
    LOGOUT,
    RESET_PASSWORD
  }
export enum appStateActionType {
    ADD_ALERT,
    DELETE_ALERT,
    ALERT_HISTORY_NEW_ALERT,
    ALERT_HISTORY_ALERTS_ACKNOWLEDGED,
    SET_LIVEMETERS,
    LOGIN,
    LOGOUT,
    RESET_PASSWORD,
    ADD_METERCODES_LOOKUP
  }