import { gql } from '@apollo/client'

// **** FARM RELATED QUERIES ****

export const GET_ALL_FARMS = gql`
  query getAllFarms {
    farms {
      _id
      name
      size
    }
  }
`
export const GET_SINGLE_FARM = gql`
  query getSingleFarm($_id: String!) {
    farm(_id: $_id) {
      _id
      name
      size
      	meters {_id name meterCode}
      }
  }
`


