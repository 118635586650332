import { useContext, useEffect, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Login from "../pages/login/Login";
import Signup from "../pages/signup/Signup";
import { tokenName } from "../globals"
import { useLazyQuery } from "@apollo/client";

import { USER_AUTHTOKEN_VERIFY } from "../queriesAndMutations/queries/user.queries"
import LoadingPage from "../pages/loading/LoadingPage";
import { AppStateContext } from "../context/appStateContext";
import { UserRoles } from "./typedefs";


const RequireAuth = ({ children }: any) => {
  const { appState, login, logout } = useContext(AppStateContext);
  const token = localStorage.getItem(tokenName)
  const tokenIsVerified = useRef<boolean>(false);

  const currentUrl = useLocation();

  const [verifyTokenWithAPI] = useLazyQuery<any>(USER_AUTHTOKEN_VERIFY);


  useEffect(() => {
    //This is used to check the token on a page refresh or reload...
    if (token) {
      verifyTokenWithAPI({
        variables: {
          authToken: token
        }
      }).then((response: any) => {
        if (response?.data) {

          //if valid data is returned set token etc via login()...
          if (response?.data?.verifyJWT) {
            tokenIsVerified.current = true;
            login && login(response?.data?.verifyJWT)
            return;
          }
          //if "null" is returned, logout and revoke token.
          tokenIsVerified.current = false;
          logout && logout();
          return;

        }

        if (response.error.message) {
          tokenIsVerified.current = false;
          logout && logout();
          return;
        }
      })
        .catch((err: any) => {
          // console.log(err)
          return;
        });
    }

  }, []);

  if (!tokenIsVerified.current && token && !appState.userId) { return <LoadingPage /> }


  if (appState.userId) {
      if(currentUrl.pathname === "/admin" && appState.userRole === UserRoles.USER){
      return <Navigate to="/" />
    }

    return currentUrl.pathname === "/login" || currentUrl.pathname === "/signup"
      ?
      <Navigate to="/" />
      :
      children
  }

  if (currentUrl.pathname === "/signup") { return <Signup /> }
  return <Login />

}

export default RequireAuth