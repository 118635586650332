import { useContext, useState } from 'react'
import {
  Formik,
  Form,
  FormikProps,
} from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'
import FormikInput from '../../components/fields/FormikInput'

// Import GraphQL queries and mutations
import { USER_LOGIN } from '../../queriesAndMutations/mutations/user.mutations'
import { AppStateContext } from '../../context/appStateContext'


interface ILoginValues {
  email: string;
  password: string;
}

const initialValues: ILoginValues = {
  email: '',
  password: ''
}

const Login = () => {

  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [loginErrors, setLoginErrors] = useState<string>("");

  const [loginUser] = useMutation<any>(USER_LOGIN);
  const { login } = useContext(AppStateContext);

  const validate = Yup.object({
    email: Yup.string().required('Email required').email('email address not valid'),
    password: Yup.string().required('Password Required')
  });


  const onSubmit = async (values: any) => {
    setIsLoggingIn(true);

    loginUser({
      variables: {
        email: values.email.toLowerCase(),
        password: values.password,
      },
    }).then((response: any) => {
      if (response.data.userLogin) {
        setIsLoggingIn(false);
        login && login(response.data?.userLogin);
      }
    })
      .catch((err: any) => {
        if(err.networkError){
          setLoginErrors("Network Error");
          setIsLoggingIn(false);
          return;
        }  
       setLoginErrors(err.message);
       setIsLoggingIn(false);
      });
  }


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validate}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formik: FormikProps<any>) => {
        return (
          <>
            <Form className="relative" id={"login"} >
              <img alt=''
                src="/assets/bg.jpg"
                className="absolute inset-0 object-cover w-full h-full saturate-0"
              />
              <div className="absolute inset-0 block w-full h-full bg-primary/80"></div>

              <div className="relative z-10 flex flex-col items-center justify-center min-h-screen mx-auto">
                <div className="divide-y-2 w-80 card divide-primary/20 rounded-2xl bg-shadow-2xl">
                  <div className="bg-primary">
                    <img
                      alt='logo-primary'
                      src="/assets/logo-primary.svg"
                      className="w-3/4 p-6 mx-auto"
                    />
                  </div>
                  <div className="bg-secondary">
                    <h1 className="p-3 text-2xl font-bold text-center text-white">
                      Account Login
                    </h1>
                  </div>
                  <div className="flex flex-col p-6 space-y-6">
                    <FormikInput
                      label="Email"
                      name="email"
                      isLoading={false}
                    />
                    <FormikInput
                      label="Password"
                      name="password"
                      isLoading={false}
                      isPassword
                    />
                  </div>
                  <div className="p-6 py-4">
                    <div className="errors mb-6 text-red-700">{loginErrors && <h1>{loginErrors}</h1>}</div>
                    <button type='submit' disabled={isLoggingIn} className="w-full button-base disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray">{isLoggingIn ? "Please wait..." : "Login"}</button>
                  </div>
                </div>
                <Link className="p-3 text-white/50 hover:text-accent" to="/reset-password">
                  Forgot Login / Password?
                </Link>
                <Link className="p-3 text-white/50 hover:text-accent" to="/signup">
                  Not registered? / Signup here
                </Link>
              </div>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

export default Login
