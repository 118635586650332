import UserSignupInput from '../../components/graphQLInputs/userInputs/UserSignupInput'

const Signup: React.FC = () => {

  return (
      <div className="flex flex-col min-h-screen site-wrapper">
        <UserSignupInput formId='user-signup'/>
      </div>
  )
}

export default Signup
