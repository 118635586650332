import React, { useContext, useEffect, useState } from 'react'
import { AppStateContext, InitialAppState } from '../../context/appStateContext'
import NormalHeader from '../../components/headers/NormalHeader'
import AdminSidebar from './AdminSidebar';
import AdminPanelWrapper from './AdminPanelWrapper';
import {adminMenuItems} from "./AdminMenuList";
import { useLocation } from 'react-router-dom';


const AdminDashboard: React.FC = () => {

  const { appState } = useContext<Partial<InitialAppState>>(AppStateContext);
  const [currentMenuItem, setCurrentMenuItem] = useState<string>("")

  const params = new URLSearchParams(useLocation().search).get('id')
  //if no id in URL set to 0 as default
  let menuId = params ? parseInt(params) -1 : 0;

  //if Id is not matched (e.g. >) set to 0
  if(menuId > adminMenuItems.length){  menuId = 0;}


  const handleAdminSidebarClick = ((menuItemName: string) => {
    setCurrentMenuItem(menuItemName)
  });

  useEffect(() => {
    //load first menu item to display some data...
    setCurrentMenuItem(adminMenuItems[menuId].split(' ').join('-').toLocaleLowerCase())
  }, [])

  return (
    <>
      <header>
        <NormalHeader title="Admin dashboard" secondaryTextArray={[`Logged in as: ${appState.userName}`, `User Role: ${appState.userRole}`]} />
      </header>
      <main>
        <div className="py-12 md:fluid-container">
          <div className="grid grid-cols-6 justify-center content-center gap-6 lg:grid-cols-12">

            {/* <!-- Admin Sidebar --> */}
            <div className='col-span-6 lg:col-span-3'>
              <AdminSidebar menuId={menuId} adminSidebarClickCallback={handleAdminSidebarClick} menuItems={adminMenuItems} />
            </div>

            {/* <!-- Admin Central Primary Panel --> */}
            <div className='col-span-6 lg:col-span-9'>
              <AdminPanelWrapper menuItem={currentMenuItem} />
            </div>
          </div>

        </div>
      </main>
    </>
  )
}

export default AdminDashboard
