import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppStateContext, InitialAppState } from '../../context/appStateContext';
import { UserRoles } from '../../utils/typedefs';

interface Props {
  toggleMenuOpenClose: () => void
}

const HeaderNav: React.FC<Props> = ({ toggleMenuOpenClose }) => {
  const { appState, logout } = useContext<Partial<InitialAppState>>(AppStateContext);

  const handleLogout = () => {
    logout && logout();
  }

  return (
    <div id="nav-primary" className="relative w-full bg-primary">
      <div className="">
        <nav className="flex flex-row justify-between w-full">
          <Link className="border-r border-white/20" to="/">
            <img
              src="assets/logo-primary.svg"
              alt=""
              aria-label="Home"
              className="w-48 p-4"
            />
          </Link>
          {/* <!-- Nav List --> */}
          <ul className="flex-row flex-wrap items-center hidden mr-auto lg:flex">
            <li>
              <Link className='text-lg link active"' to="/">
                <img src="/assets/icon-meter.svg" className="w-6 h-6 mr-2" />
                <span>Live Meters</span>
              </Link>
            </li>
            <li>
              <Link className='text-lg link active"' to="/alerts">
                <span className="relative">
                  Alerts
                  {!appState?.allAlertHistoryAlertsSeen && appState?.alerts?.length > 0 &&  <div className="absolute inline-flex w-4 h-4 text-xs text-white bg-red-700 rounded-full -top-1 -right-4 place-content-center">
                    {appState?.alertHistoryNewAlertCount}
                  </div>}
                </span>
              </Link>
            </li>
          </ul>
          <ul className="flex-row flex-wrap items-center hidden font-medium lg:flex">
          {appState.userRole !== UserRoles.USER &&  <li>
              <Link className="link" to="/admin">
                Admin Dash
              </Link>
            </li>}
            <li>
              <Link className="link" to="/settings">
                Settings
              </Link>
            </li>
            <li>
              <Link onClick={handleLogout} className="link" to="/login">
                Logout
              </Link>
            </li>
          </ul>
          {/* <!-- Mobile Nav Button --> */}
          <div className="flex flex-row items-center py-2 ml-auto lg:hidden">

            <Link className="relative self-center mx-2 text-white lg:hidden mburger hover:text-dark" to="/alerts">
              <img src="/assets/icon-bell.svg" className="w-8 h-8" />
              {!appState?.allAlertHistoryAlertsSeen && appState?.alerts?.length > 0 && <div className="absolute inline-flex w-4 h-4 text-xs text-white bg-red-700 rounded-full place-content-center mobile-notification">
              </div>}
            </Link>
            <button
              onClick={toggleMenuOpenClose}
              id="mburger"
              className="self-center mx-2 text-white lg:hidden mburger hover:text-dark"
              aria-label="Toggle navigation"
            >
              <img src="/assets/icon-menu.svg" className="w-12 h-12" />
            </button>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default HeaderNav
