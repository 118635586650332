import { gql } from '@apollo/client'

// **** USER RELATED QUERIES ****

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    me {
    _id
    name
    email
    alertHistoryAlertCount
    alertHistoryAlertsSeen
    userRole
   }
  }
`

export const USER_AUTHTOKEN_VERIFY = gql`
query verifyJWT($authToken: String!){
  verifyJWT(authToken: $authToken) 
}`;