import { useMutation } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import NormalHeader from '../../components/headers/NormalHeader'
import { USER_SIGN_UP_TOKEN_VERIFY } from "../../queriesAndMutations/mutations/user.mutations"
import { tokenName } from "../../globals"
import { appStateActionType } from '../../context/actionTypes'
import { AppStateContext, InitialAppState } from '../../context/appStateContext'

type Props = {}


const SignupOrResetTokenVerify = (props: Props) => {
  const [verifyToken] = useMutation<any>(USER_SIGN_UP_TOKEN_VERIFY);
  const [verifyErrors, setVerifyErrors] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const verificationToken: string = new URLSearchParams(useLocation().search).get("token")!;
  const reset: string = new URLSearchParams(useLocation().search).get("reset")!;

  const { dispatch } = useContext<Partial<InitialAppState>>(AppStateContext);

  useEffect(() => {
    setIsLoading(true);
    verifyToken({
      variables: {
        verificationToken
      }
    }).then((response: any) => {
      if (response.data.userVerify) {
        localStorage.setItem(tokenName, response.data?.userVerify?.authToken);
        setIsLoading(false);
        if (reset) {
         dispatch!({ type: appStateActionType.RESET_PASSWORD, payload: response.data?.userVerify?.tempResetPassword });
        navigate("/settings/?reset=true");
        } else {
          navigate("/");
        }
      }
    })
      .catch((err: any) => {
        setVerifyErrors(err.message);
        setIsLoading(false);
      });
  }, [])


  return isLoading ? null : (
    <div>
      <NormalHeader title={reset ? "Password Reset Token Verification" : "Signup Verify"} />
      <div className="flex flex-col items-center justify-between mt-8 px-4 pb-2 md:px-0">
        <h2 className="text-xl font-bold text-primary mb-4">Token Verification:</h2>

        <div className="card">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-flex min-w-full text-center">
                <div className="w-full overflow-hidden p-16">
                  {verifyErrors && <h1 className='text-red-800 mb-4'>{verifyErrors}</h1>}
                  <p>An error has occurred validating that token.</p>
                  <p> Please try again.</p>
                  <Link className="button-base mt-6" to={reset ? ".login" : "/signup"}>
                    {reset ? "Go to login" : "Go to Signup"}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignupOrResetTokenVerify