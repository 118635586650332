import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { AppStateContext, InitialAppState } from '../../context/appStateContext';
import { UserRoles } from '../../utils/typedefs';

//import local styles
import "./MobileNav.css"

interface Props {
  toggleMenuOpenClose: () => void;
}

const MobileNav:React.FC<Props> = ({toggleMenuOpenClose}) => {
  const { appState, logout} = useContext<Partial<InitialAppState>>(AppStateContext);

  const handleLogout = () => {
    logout && logout();
    toggleMenuOpenClose();
  }

  return (
      <div className="mobile-nav absolute inset-0 z-50 w-full min-h-full p-12 bg-primary">
        <div className="flex flex-row items-center justify-between space-x-8">
            <img src="/assets/logo-primary.svg" className="w-48"/>
            <button onClick={toggleMenuOpenClose} >
              <img src="/assets/icon-close.svg" className="w-8 h-8"/>
            </button>
        </div>
      <nav>
        <ul className="py-8">
          <li> <Link onClick={toggleMenuOpenClose} className="inline-flex w-full px-2 py-4 text-2xl text-white transition rounded-lg active:ring-accent active:ring-4 active:bg-white/10 hover:text-accent" to='/'>Live Meters</Link></li>
          <li> <Link onClick={toggleMenuOpenClose} className="inline-flex w-full px-2 py-4 text-2xl text-white transition rounded-lg active:ring-accent active:ring-4 active:bg-white/10 hover:text-accent" to='/alerts'>Alerts</Link></li>
            {appState.userRole !== UserRoles.USER &&  <li> <Link onClick={toggleMenuOpenClose} className="inline-flex w-full px-2 py-4 text-2xl text-white transition rounded-lg active:ring-accent active:ring-4 active:bg-white/10 hover:text-accent" to='/admin'>Admin Dashboard</Link></li>}
          <li> <Link onClick={toggleMenuOpenClose} className="inline-flex w-full px-2 py-4 text-2xl text-white transition rounded-lg active:ring-accent active:ring-4 active:bg-white/10 hover:text-accent" to='/settings'>Settings</Link></li>
          <li> <Link onClick={handleLogout} className="inline-flex w-full px-2 py-4 text-2xl text-white transition rounded-lg active:ring-accent active:ring-4 active:bg-white/10 hover:text-accent" to='/login'>Logout</Link></li>
        </ul>
      </nav>
    </div>
  )
}

export default MobileNav
