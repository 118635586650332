import { gql } from '@apollo/client'


// **** READINGS RELATED QUERIES ****
export const GET_ALL_READINGS = gql`
   query getAllReadings {
    getAllReadings {
      _id
      meterNumber
      readingValue
      readingDatetime
      createdBy
    }
  }
`