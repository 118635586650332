import { LazyQueryExecFunction, OperationVariables, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { createContext, FC, useEffect, useReducer } from 'react'
import { IUser, tokenName } from '../globals';
import { RESET_ALERT_HISTORY_NOTIFICATIONS } from '../queriesAndMutations/mutations/alert.mutations';
import { GET_CURRENT_USER } from '../queriesAndMutations/queries/user.queries';
import client from '../utils/apolloClient';
import { appStateActionType } from './actionTypes';
import jwt_decode from "jwt-decode";
import {  UserRoles } from '../utils/typedefs';
import { GET_ALERT_HISTORY } from '../queriesAndMutations/queries/alert.queries';


export type InitialAppState = {
  alerts: Array<string>;
  allAlertHistoryAlertsSeen: boolean;
  alertsLoadedFromAPI: boolean;
  alertHistoryNewAlertCount: number | null;
  dispatch: React.Dispatch<any>;
  appState: any;
  resetAlertHistoryNotifyCountOnDB: any;
  userId: IUser | null;
  userName: string;
  userRole: UserRoles;
  tempResetPassword: string;
  login?: (token: string) => void;
  logout?: () => void;
}

const initialAppState: Partial<InitialAppState> = {
  alerts: [],
  alertsLoadedFromAPI: false,
  allAlertHistoryAlertsSeen: false,
  alertHistoryNewAlertCount: null,
  userId: null,
  userName: "",
  userRole: UserRoles.USER,
  tempResetPassword: ""
}

const AppStateContext = createContext(initialAppState);

const appStateReducer = (appState: any, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case appStateActionType.ADD_ALERT:
      return {
        ...appState,
        alerts: payload,
        alertsLoadedFromAPI: true

      }
    case appStateActionType.ALERT_HISTORY_ALERTS_ACKNOWLEDGED:
      return {
        ...appState,
        allAlertHistoryAlertsSeen: true,
        alertHistoryNewAlertCount: 0
      }
    case appStateActionType.ALERT_HISTORY_NEW_ALERT:
      return {
        ...appState,
        alertHistoryNewAlertCount: payload.alertCount,
        allAlertHistoryAlertsSeen: payload.alertsSeen,
        userName: payload.userName,
        userRole: payload.userRole
      }
    case appStateActionType.SET_LIVEMETERS:
      return {
        ...appState,
        liveMeters: payload
      }
      case appStateActionType.LOGIN:
        return {
          ...appState,
          userId: payload
        }
      case appStateActionType.LOGOUT:
        return {
          ...initialAppState
        }
      case appStateActionType.RESET_PASSWORD:
        return {
          ...appState,
          tempResetPassword: payload
        }
    default:
      return appState;
  }
}

const AppStateProvider = ({ children }: any) => {
  const [appState, dispatch] = useReducer(appStateReducer, initialAppState);
  const [resetAlertNotifications] = useMutation<any>(RESET_ALERT_HISTORY_NOTIFICATIONS);
  const [getCurrentUser, {data: userData, loading: currentUserLoading, called: currentUserCalled}] = useLazyQuery<any>(GET_CURRENT_USER, {
    fetchPolicy: 'network-only', 
    nextFetchPolicy: 'network-only', 
  });

  const alertHistoryPolllingInterval = 3600000; //poll every 1 hour

  const [alertHistory, {data: alertHistoryData, loading: alertHistoryLoading, called: alertHistoryCalled, startPolling:alertHistoryStartPolling, stopPolling:alertHistoryStopPolling, }]  = useLazyQuery<any, OperationVariables>(GET_ALERT_HISTORY,
     {pollInterval: alertHistoryPolllingInterval}); 


  useEffect(() => {
    if(!currentUserCalled){
         getCurrentUser();
    }
  }, [])


  useEffect(() => {
    if(!alertHistoryLoading && alertHistoryCalled){
      alertHistoryData?.alertHistory?.length && dispatch!({ type: appStateActionType.ADD_ALERT, payload: alertHistoryData?.alertHistory });
      getCurrentUser();
    }
  }, [alertHistoryData])



  useEffect(() => {
    if (!currentUserLoading && userData?.me) {
      dispatch!({ type: appStateActionType.ALERT_HISTORY_NEW_ALERT, payload: { 
        alertCount: userData?.me?.alertHistoryAlertCount, 
        alertsSeen: userData?.me?.alertHistoryAlertsSeen,
        userName: userData?.me?.name,
        userRole: userData?.me?.userRole
      } });
    }

  }, [userData, currentUserLoading])



  const resetAlertHistoryNotifyCountOnDB = async () => {
    resetAlertNotifications()
      .then(() => {
        dispatch!({ type: appStateActionType.ALERT_HISTORY_ALERTS_ACKNOWLEDGED });
      }).catch((err: any) => {
        console.log(err.message);
        return;
      })
  }

  const login = (token: string) => {
    if (token) {
      localStorage.setItem(tokenName, token);
      let idFromToken: Partial<IUser> = jwt_decode(token);
      //Begin Alert history polling...
      alertHistory();
      alertHistoryStartPolling(alertHistoryPolllingInterval);
      dispatch!({ type: appStateActionType.LOGIN, payload: idFromToken._id })
    }
  }
  const logout = () => {
    localStorage.removeItem(tokenName);
    client.resetStore().catch(() => {
      //catch errors and return nothing...
    });
    alertHistoryStopPolling();
    dispatch!({ type: appStateActionType.LOGOUT });
  }
  
  return (
    <AppStateContext.Provider
      value={{ appState, dispatch, login, logout, resetAlertHistoryNotifyCountOnDB }}>{children}</AppStateContext.Provider>
  )
}

export { AppStateContext, AppStateProvider }

