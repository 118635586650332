import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import * as Yup from 'yup'
import { Form, Formik, FormikProps } from 'formik'

// Import GraphQL queries and mutations

import FormikInput from '../../fields/FormikInput'
import { IMessage } from '../../../globals'
import { ADD_NEW_METER_READING } from '../../../queriesAndMutations/mutations/readings.mutations'
import moment from 'moment'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { GET_ALL_READINGS } from '../../../queriesAndMutations/queries/readings.queries'


type Props = {
  formId: string;
  farmName?: string;
  meterName?: string;
  meterNumber?: string;
  isAdminDash: boolean;
  handleClose?: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateResponse: React.Dispatch<React.SetStateAction<IMessage>>;

}


type InitialValues = {
  farmName: string;
  meterNumber: string;
  readingValue: string;
  readingDatetime: string;
}

const initialValues = {
  farmName: '',
  meterNumber: '',
  readingValue: '',
  readingDatetime: '',
}

const ReadingCreateInput: React.FC<Props> = ({ formId, farmName = '',  meterNumber = '', isAdminDash, handleClose, setUpdateResponse }) => {

  const [addNewMeterReading] = useMutation<any>(ADD_NEW_METER_READING,{
    refetchQueries: [
      {
        query: GET_ALL_READINGS,
      },
    ],
  });
  const [readingDatetime, setReadingDatetime] = useState(new Date());

  //state to handle edited values
  const [valuesToEdit, setValuesToEdit] = useState<InitialValues>(initialValues);

  const validationSchema = Yup.object({
      readingValue: Yup.mixed()
    .required('Meter Value is required')
    .test('type', "Must be a number", (value :any) => !isNaN(value))
    .test('farmSize', "Must not exceed 3 decimal places",(value: any) => /^\d*.?\d{0,3}$/.test(value)),
  });

  const onSubmit = (values: any) => {

    setUpdateResponse && setUpdateResponse({ text: "Adding Meter Reading. Please wait...", colour: "#2b679b" });

    addNewMeterReading({
      variables: {
        "input": {
          meterNumber: values.meterNumber,
          readingValue: parseFloat(values.readingValue),
          readingDatetime: readingDatetime
        }
      },
    }).then((response: any) => {
      if (response.data) {
        setUpdateResponse && setUpdateResponse({ text: "Meter Reading added successfully!", colour: "green" });
        setDefaults();
      }
    })
      .catch((err: any) => {
        setUpdateResponse && setUpdateResponse({ text: err.message, colour: "red" });
        setDefaults();

      });;


    //Close modal from passed down setState prop
    handleClose && handleClose(false);
  }


  const handleFormChange = (e: any) => {
    setValuesToEdit((prev) => {
      return { ...prev, [e.id]: e.value }
    })
  }

const setDefaults = () => {
  setValuesToEdit({
    farmName,
    meterNumber,
    readingValue: "",
    readingDatetime: moment().format('YYYY-MM-DD HH:mm')
  })
}

  useEffect(() => {
    setDefaults();
  }, [])


  return (
    <Formik
      initialValues={valuesToEdit}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {(formik: FormikProps<any>) => {
        return (

          <div className="w-full my-12 card divide-primary/10">
            <div className="p-4 px-6 card-topper ">
              <h3 className="text-xl font-bold text-primary">

                Add Meter Reading
              </h3>
            </div>


            <Form id={formId} onChange={(e) => handleFormChange(e.target)}>
              <div className="card-content">
                <div className="flex flex-col justify-between p-4 px-6 space-y-4 md:space-x-8 md:flex-row md:space-y-0">

                 {!isAdminDash && <label className='font-bold md:w-1/5'>Farm Name
                    <div className='flex flex-col font-normal justify-center' style={{ height: "52px" }}><h2>{farmName}</h2></div>
                  </label>}
                {!isAdminDash && <label className='font-bold md:w-1/5'>Meter Number
                    <div className='flex flex-col font-normal justify-center' style={{ height: "52px" }}><h2>{meterNumber}</h2></div>
                  </label>}

                 {isAdminDash && <FormikInput
                    label="Meter Number"
                    name="meterNumber"
                    onLoadValue={valuesToEdit.meterNumber}
                    isLoading={false}
                  />}
                  <FormikInput
                    label="Meter Value"
                    name="readingValue"
                    onLoadValue={valuesToEdit.readingValue}
                    isLoading={false}
                  />
                  <label className='font-bold'>Reading Date/Time
                    <DatePicker
                      className="block w-full pr-8 transition border-2 border-gray-300 rounded-md shadow-sm hover:bg-secondary/10 focus:border-secondary focus:ring focus:ring-secondary/50 focus:ring-opacity-50 bg-secondary/5"
                      timeInputLabel="Time:"
                      showTimeInput
                      dateFormat="dd/MM/yyyy h:mm aa"
                      selected={readingDatetime} onChange={(date: any) => setReadingDatetime(date)} 
                      />
                     
                  </label>
                </div>
              </div>
            </Form>


            <div className="card-footer">
              <div className="p-4 px-6">
                <button
                  form={formId}
                  className="button-base disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray "
                  type="submit"
                  disabled={valuesToEdit.readingValue.length < 1}
                >
                  Add New Reading
                </button>
              </div>
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

export default ReadingCreateInput
