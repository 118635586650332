import NormalHeader from '../../components/headers/NormalHeader'


import {ReactComponent as LoadingSpinner} from '../../assets/loading.svg';

const LoadingPage = () => {
  return (
    <div>
      <NormalHeader title="Loading dashboard" />
      <div className="flex flex-col items-center justify-between mt-8 px-4 pb-2 md:px-0">
        <h2 className="text-xl font-bold text-primary mb-4">One moment please...
        </h2>
        <LoadingSpinner  className=" w-1/3 p-4 "  />
    </div>
    </div>
  )
}

export default LoadingPage