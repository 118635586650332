import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import * as Yup from 'yup'
import { Form, Formik, FormikProps } from 'formik'

// Import GraphQL queries and mutations
import { ADD_NEW_ALERT } from '../../../queriesAndMutations/mutations/alert.mutations'
import { UPDATE_ALERT } from '../../../queriesAndMutations/mutations/alert.mutations'
import { GET_ALL_METERS } from '../../../queriesAndMutations/queries/meter.queries'
import { GET_SINGLE_ALERT } from '../../../queriesAndMutations/queries/alert.queries'
import { GET_ALL_ALERTS } from '../../../queriesAndMutations/queries/alert.queries'
import FormikSelect from '../../fields/FormikSelect'
import FormikInput from '../../fields/FormikInput'
import { useGetAlertSpec } from '../../../hooks/useGetAlertSpec'
import { Link } from 'react-router-dom'
import { IMessage } from '../../../globals'


type Props = {
  alertToEditId?: string;
  editMode: boolean;
  formId: string;
  farmName?: string;
  meterId?: string;
  volume?: string;
  alertSpecType?: string;
  handleClose?: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateResponse: React.Dispatch<React.SetStateAction<IMessage>>;
}

interface IAlertValues {
  _id: string;
  meterId: string;
  alertSpec: string;
  volume: string;
  farmName: string;
}

const initialValues: IAlertValues = {
  _id: '',
  meterId: '',
  alertSpec: '',
  volume: '',
  farmName: ''
}

const AlertCreateInput: React.FC<Props> = ({ editMode, alertSpecType = '', alertToEditId = '', meterId = '', volume = '', farmName ='', formId, handleClose, setUpdateResponse }) => {

  const [addNewAlert, { called: addAlertCalled, loading: addAlertLoading, reset: alertMutationReset }] = useMutation<any>(ADD_NEW_ALERT);
  const [updateAlert] = useMutation<any>(UPDATE_ALERT);
  const allMeters = useQuery<any>(GET_ALL_METERS,{
    skip: editMode,
  });

  //Utilise the useGetAlertSpec hook to get AlertSpec types 
  const alertSpecTypes = useGetAlertSpec().getAlertSpecTypes();



  //state to handle edited values
  const [valuesToEdit, setValuesToEdit] = useState<IAlertValues>(initialValues);

  const validationSchema = Yup.object({
    meterId: Yup.string().required('Meter Required'),
    alertSpec: Yup.string().required('Alert Spec Required'),
    volume: Yup.mixed()
    .required('Volume required')
    .test('type', "Must be a number", (value :any) => !isNaN(value))
    .test('volume', "Must not exceed 2 decimal places",(value: any) => /^\d*.?\d{0,2}$/.test(value)),
  });

  const onSubmit = (values: IAlertValues) => {
    if (editMode) {
      if (values.volume !== volume || values.alertSpec !== alertSpecType) {   //If values have't changed on edit - don't mutate via API
        setUpdateResponse && setUpdateResponse({ text: "Updating the Alert. Please wait...", colour: "#2b679b" });
        updateAlert({
          variables: {
            _id: values._id,
            meterId: values.meterId,
            alertSpec: values.alertSpec,
            volume: parseFloat(values.volume)
          },
        }).then((response: any) => {
          if (response.data) {
            setUpdateResponse && setUpdateResponse({ text: "Alert updated successfully!", colour: "green" });
            
          }
        })
          .catch((err: any) => {
            setUpdateResponse && setUpdateResponse({ text: err.message, colour: "red" });
            
          });;;
      }
    } else {
      setUpdateResponse && setUpdateResponse({ text: "Adding New Alert. Please wait...", colour: "#2b679b" });
      addNewAlert({
        variables: {
          meterId: values.meterId,
          alertSpec: values.alertSpec,
          volume: parseFloat(values.volume)
        },

        update(cache, { data }) {

          if (!data.alertCreate) return;

          const alertsQuery: any = cache.readQuery({ query: GET_ALL_ALERTS });
          cache.writeQuery({
            query: GET_ALL_ALERTS,
            data: { alerts: [...alertsQuery?.alerts, data.alertCreate] }
          });

        }
      }).then((response: any) => {
        if (response.data) {
          setUpdateResponse && setUpdateResponse({ text: "Alert added successfully!", colour: "green" });
          
        }
      })
        .catch((err: any) => {
          setUpdateResponse && setUpdateResponse({ text: err.message, colour: "red" });
          
        });;;
    }

    //Close modal from passed down setState prop
    handleClose && handleClose(false);
  }

  const handleFormChange = (e: any) => {
    setValuesToEdit((prev) => {
      return { ...prev, [e.id]: e.value }
    })
  }


  useEffect(() => {
    if (!addAlertLoading && addAlertCalled) {
      setValuesToEdit(initialValues);
      alertMutationReset();
    }
  }, [addAlertCalled, addAlertLoading])


  useEffect(() => {
    editMode && setValuesToEdit({
      _id: alertToEditId,
      meterId: meterId,
      alertSpec: alertSpecType,
      volume: volume,
      farmName: farmName,
    })
  }, [volume])





  return (
    <Formik
      initialValues={valuesToEdit}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {(formik: FormikProps<any>) => {
        return allMeters.loading ? null : (
          <>
           <div className="w-full my-12 card divide-primary/10">
              <div className="p-4 px-6 card-topper ">
                <h3 className="text-xl font-bold text-primary">
                  {editMode ? 'Edit Alert' : 'Add New Alert'}
                </h3>
              </div>
              {!editMode && !allMeters.loading && !allMeters?.data?.meters?.length ?
                (<table className="min-w-full">
                  <tbody>
                    <tr>
                      <td>You can't add an Alert yet. Go to <u><Link to={"/settings"}>Settings</Link> </u> and add a Meter to begin.</td>
                    </tr>
                  </tbody>
                </table>)
                : (
                  <Form id={formId} onChange={(e) => handleFormChange(e.target)}>
                    <div className="card-content">
                      <div className="flex flex-col flex-wrap p-4 px-6 space-y-4 md:space-x-8 md:flex-row md:space-y-0">
                        {editMode && <label className='font-bold '>Meter (Farm Name)
                          <div className='flex flex-col font-normal justify-center' style={{ height: "52px" }}><h2>{farmName}</h2></div>
                          {/* <div className='flex flex-col font-normal justify-center' style={{ height: "52px" }}><h2>{alertToEdit?.data?.alert ? `${alertToEdit?.data?.alert?.meter.name} (${alertToEdit?.data?.alert?.farm.name})` : "Loading..."}</h2></div> */}
                        </label>}

                        {!editMode && <FormikSelect
                          label="Select a meter"
                          name="meterId"
                          meterFarmCombine={true}
                          onLoadDropdownValue={
                            editMode ? alertToEditId : valuesToEdit.meterId
                          }
                          dropDownOptions={
                            allMeters?.loading
                              ? null
                              : allMeters?.data?.meters
                          }
                        />}

                        <FormikSelect
                          label="Alert Trigger"
                          name="alertSpec"
                          onLoadDropdownValue={
                            editMode ? valuesToEdit.alertSpec ? valuesToEdit.alertSpec : alertSpecType : valuesToEdit.alertSpec
                          }
                          dropDownOptions={
                            allMeters?.loading
                              ? null
                              : alertSpecTypes
                          }
                        />
                        <FormikInput
                          label="Volume Exceeded"
                          name="volume"
                          unit="m3"
                          isLoading={allMeters?.loading || allMeters?.error ? true : false}
                          onLoadValue={valuesToEdit.volume.toString()}
                        />
                      </div>
                    </div>
                  </Form>)
              }
              <div className="card-footer">
                <div className="p-4 px-6">
                  <button
                    form={formId}
                    className="button-base disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray "
                    type="submit"
                    disabled={!formik.isValid || [formik.values.meterId, formik.values.alertSpec].includes("Select one...")} //Select one comes from FormikSelect component
                  >
                    {editMode ? 'Save Alert' : 'Add New Alert'}
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      }}
    </Formik>
  )
}

export default AlertCreateInput
