import React, { useState } from 'react'
import AlertCreateInput from '../../components/graphQLInputs/createInputs/AlertCreateInput'
import NormalHeader from '../../components/headers/NormalHeader'
import { IMessage } from '../../globals'
import AlertHistory from './AlertHistory'
import AlertSetup from './AlertSetup'



type Props = {}

const Alerts: React.FC<Props> = () => {

  const [updateResponse, setUpdateResponse] = useState<IMessage>({ text: "", colour: "red" });

  return (
    <div className="flex flex-col min-h-screen site-wrapper">
      <NormalHeader title={'Alerts'} />

      <div className="py-12 space-y-8 md:fluid-container">
        <AlertHistory />
        <div className="hidden md:block">
          <hr className="border-primary/20" />
        </div>
        <AlertSetup setUpdateResponse={setUpdateResponse} updateResponse={updateResponse} />
        <AlertCreateInput formId='new-alert' editMode={false} setUpdateResponse={setUpdateResponse} />
      </div>
    </div>
  )
}

export default Alerts
