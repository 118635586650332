import React from 'react'
import ReactDom from 'react-dom'

interface Props {
  isOpen: boolean;
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}


const Modal = ({ isOpen, children, onClose: handleClose }: Props) => {
  return isOpen
    ? ReactDom.createPortal(
        <>
          <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex flex-col items-center justify-center">
            <div className="modal-content w-11/12 xl:w-[1000px] rounded-xl bg-white bg-opacity-75 p-4 flex flex-col items-end justify-center">
              <div className="modal-content">
                <button
                  className="cursor-pointer text-md hover:text-blue-800 button-table bg-red-500 hover:bg-red-300 hover:text-blue-800 text-white"type="button"
                  onClick={() => handleClose(false)}
                >
                  Cancel
                </button>
              </div>
              {React.Children.map(children, (child) =>
                React.cloneElement(child, { handleClose }),
              )}
            </div>
          </div>
        </>,
        document.getElementById('modal') as HTMLElement,
      )!
    : null
}

export default Modal
