
import { useContext, useState } from 'react';
import { AppStateContext, InitialAppState } from '../../context/appStateContext';

type Props = {
    menuItems: Array<string>;
    menuId: number;
    adminSidebarClickCallback: (menuItemName: string) => void;
}

const AdminSidebar: React.FC<Props> = ({ menuItems, menuId, adminSidebarClickCallback }) => {
    const [currentlySelectedMenuItem, setCurrentlySelectedMenuItem] = useState<number>(menuId);
    const { appState} = useContext<Partial<InitialAppState>>(AppStateContext);

    const handleMeterNameClick = (menuItemName: string, menuItemIndex: number) => {
        adminSidebarClickCallback(menuItemName.split(' ').join('-').toLocaleLowerCase());
        setCurrentlySelectedMenuItem(menuItemIndex);
    }

    return (
        <div className="card flex flex-col items-center justify-center px-4 pt-4 pb-8 lg:sticky top-16"> 
        <h2 className="text-xl mb-4 font-bold text-primary">Admin Menu</h2>
        <nav id='mobile-nav' className="space-x-2 text-primary flex flex-row lg:flex-col lg:space-y-2 lg:space-x-0">
            {menuItems && menuItems?.map((menuItem: string, idx: number) => {
              return <ul key={idx} className="flex flex-col">
                    <li key={idx}>
                        <a onClick={() => handleMeterNameClick(menuItem, idx)}
                            className="inline-flex w-full cursor-pointer py-2 px-4 transition rounded-lg hover:bg-secondary/20"
                            style={{background: idx === currentlySelectedMenuItem ? "#97dbf1" : ""}}
                            >
                            {menuItem}
                        </a>
                    </li>
                </ul>
            })}
        </nav>
        </div>

    )
}

export default AdminSidebar
