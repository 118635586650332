import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {tokenName} from "../globals"
import { onError } from "@apollo/client/link/error";

const errorLink = onError(({ graphQLErrors, networkError }) => {

  // if (networkError) {
  //   console.log(`[Network error]: ${networkError} ${networkError.name}`);
  //   }
    
  console.log("An error has occurred in the main client");

});
const apiHttpLink: any = createHttpLink({
    uri: process.env.REACT_APP_API_URL
});

  const authLink: ApolloLink = setContext((_, {headers}) => {
    return {
        headers: {
            ...headers,
            authorization: localStorage.getItem(tokenName) || ""
        }
    }
  });
  
  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: authLink.concat(apiHttpLink),
    cache: new InMemoryCache()
  });


  export default client;