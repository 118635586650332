
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react'
import { LineChart } from '../../../components/Charts'
import 'chartjs-adapter-moment';
import { appTimeZone } from '../../../globals';
import { defaultTitle, IChartDataSet, initialChartDataSet, initialDatasetOptions } from './chartCard.globals';

type Props = {
  dailyMeterData: any;
  dailyMeterError: any;
  isLoading: boolean;
  timestamps: Date[];
}


const DailyChartCard: React.FC<Props> = ({ dailyMeterData, isLoading, timestamps, dailyMeterError }) => {
  const [dailyChartData, setDailyChartDataChartData] = useState<IChartDataSet>(initialChartDataSet);
  const LPERMIN_TO_M3PERH = 0.06;
  let dailyChartPoints: [{ x: Date, y: number }];

  useEffect(() => {
    if (!dailyMeterData?.meterData?.length) { return };

    setDailyChartDataChartData((prev) => {
      return {
        ...prev,
        datasets: [{
          ...prev.datasets[0],
          data: dailyChartPoints
        }],
      }
    });

    
  }, [ dailyMeterData])

  const getMaxY = (inputArray: [{ x: Date, y: number }]): number => {
    let y: any = -Infinity;
    for (let p of inputArray) {
      if (y < p.y) y = p.y;

    }
    return y;
  };


  const dailyChartConfigOptions = () => {
    try {
      if (!dailyMeterData?.meterData?.length) { return };
      
      dailyChartPoints = dailyMeterData?.meterData?.map((point: any) => ({
        x: moment.tz(point.timestamp, appTimeZone).format('ddd DD MMM YYYY HH:mm'),
        y: point.delta / point.timeDelta * LPERMIN_TO_M3PERH
      }));
      

      const dailyMaxY = getMaxY(dailyChartPoints);

      let dailyDatasetOptions = Object.assign({}, initialDatasetOptions);

      return {
        ...dailyDatasetOptions,
        scales: {
          y: {
            ...defaultTitle.y,
            min: 0,
            max: Math.ceil(dailyMaxY / 0.5) * 0.5,
            ticks: {
              // forces step size to be 2 units
              stepSize: 2
            }
          },
          x: {
            ...defaultTitle.x,
            type: 'time',
            min: moment().tz(appTimeZone).subtract(24, 'hours').format('YYYY MM DD HH:mm'),
            max: moment.tz((moment().add(1, 'hours'), appTimeZone)).format('YYYY MM DD HH:mm')
          }
        },
      }
    } catch (error) {
      console.log(error)

    }

  }



  const increaseOrDecrease = (value: number) => {
    if (value > 0) { return "Increase" }
    if (value === 0) { return "Same" }

    return "Decrease";
  }


  const dailyConfigOptions = dailyChartConfigOptions();



  
  return (
    <div className="py-12 space-y-8">
      {/* DAILY CHART */}
      <section className="">
        <div className="flex flex-row items-center justify-between px-4 pb-2 md:px-0">
          <h2 className="text-xl font-bold text-primary">Daily</h2>
          <div className="text-sm opacity-50 text-primary">{`${moment(timestamps[0]).format('DD MMM')} - ${moment(timestamps[timestamps.length - 1]).format('DD MMM')}`}</div>
        </div>
        <div className="w-full card">
          <ul className="flex flex-row items-end p-4 border-b text-primary border-primary/25">

         
            {isLoading && <span className="text-xl font-bold text-green-800">Loading Daily chart data...</span>}  
            {!dailyMeterData?.meterData?.length && !dailyMeterError && !isLoading && <span className="text-xl font-bold text-red-800">No samples found.</span> }

            {dailyMeterError && <span className="text-xl font-bold text-green-800">{dailyMeterError.message}</span>}  
            {dailyMeterData?.meterData?.length && !isLoading && <>
              <li className="px-4 border-l first-of-type:border-0 border-primary/25 last-of-type:pl-4 last-of-type:ml-auto last-of-type:pr-0 first-of-type:pl-0">
                <span className="text-xl font-bold">{dailyMeterData && (parseFloat(dailyMeterData?.usage24hours) / 1000).toFixed(3).toLocaleString()}<sup className="px-0.5 text-xs font-normal opacity-50">m3</sup></span>
                <span className="pl-0.5 tracking-tight opacity-80"> - Last 24 hours</span>
              </li>
              <li className="px-4 border-l first-of-type:border-0 border-primary/25 last-of-type:pl-4 last-of-type:ml-auto last-of-type:pr-0 first-of-type:pl-0">
                <span className="text-xl font-bold">{dailyMeterData && (parseFloat(dailyMeterData?.usagePrevious24hours) / 1000).toFixed(3).toLocaleString()}<sup className="px-0.5 text-xs font-normal opacity-50">m3</sup></span>
                <span className="pl-0.5 tracking-tight opacity-80"> Previous 24 hours</span>
              </li>
              <li className="px-4 border-l first-of-type:border-0 border-primary/25 last-of-type:pl-4 last-of-type:ml-auto last-of-type:pr-0 first-of-type:pl-0">
                <span className="text-xl font-bold">{dailyMeterData && (parseFloat(dailyMeterData?.dailyUsageDifference) / 1000).toFixed(3).toLocaleString()}<sup className="px-0.5 text-xs font-normal opacity-50">m3</sup></span>
                <span className="pl-0.5 tracking-tight opacity-80">{increaseOrDecrease(dailyMeterData?.dailyUsageDifference)}</span>
              </li>
            </>
            }
          </ul>
          <div className="relative w-full p-4 h-100">
            {dailyMeterData && <LineChart chartConfigOptions={dailyConfigOptions} chartData={isLoading ? initialChartDataSet : dailyChartData} />}
          </div>
        </div>
      </section>
    </div>
  )
}

export default DailyChartCard