import React, { useContext, useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import * as Yup from 'yup'
import { Form, Formik, FormikProps } from 'formik'

// Import GraphQL queries and mutations
import { UPDATE_USER } from '../../../queriesAndMutations/mutations/user.mutations'
import { GET_CURRENT_USER } from '../../../queriesAndMutations/queries/user.queries'
import FormikInput from '../../fields/FormikInput'
import { AppStateContext, InitialAppState } from '../../../context/appStateContext'
import { usePasswordReset } from '../../../hooks/usePasswordReset'
import { IMessage, messageTimerDisplayTime } from '../../../globals'


type Props = {
  reset: boolean;
  formId: string;
  handleClose?: React.Dispatch<React.SetStateAction<boolean>>;
}



type InitialValues = {
  _id: string;
  name: string;
  email: string;
  passwordCurrent: string,
  password: string,
  passwordAgain: string
}

const initialValues = {
  _id: '',
  name: '',
  email: '',
  passwordCurrent: '',
  password: '',
  passwordAgain: ''
}

const UserInput: React.FC<Props> = ({ formId, handleClose, reset }) => {

  //state to handle edited values
  const [valuesToEdit, setValuesToEdit] = useState<InitialValues>(initialValues);
  const [updateResponse, setUpdateResponse] = useState<IMessage>({ text: "", colour: "red" });
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [changeEmail, setChangeEmail] = useState<boolean>(false);
  const [changeUserName, setChangeUsername] = useState<boolean>(false);
  const [formEdited, setFormEdited] = useState<boolean>(false);
  const [isPasswordReset, setIsPasswordReset] = useState<boolean>(reset);

  const emailAndUserValueBeforeCancel = useRef<{ email: " ", name: "" }>({ email: " ", name: "" });
  const userUpdateSuccess = useRef<boolean>(false);
  const paswordResetLoading = useRef<boolean>(false);

  const { appState } = useContext<Partial<InitialAppState>>(AppStateContext);
  const pwdReset = usePasswordReset();


  const [updateUser] = useMutation<any>(UPDATE_USER);

  const currentUser = useQuery<any>(GET_CURRENT_USER, {
    onCompleted: (data) => {
      if (data.me) {
        setValuesToEdit((prev) => {
          return { ...prev, _id: data.me._id, name: data.me.name, email: data.me.email }
        });
        emailAndUserValueBeforeCancel.current = { email: data.me.email, name: data.me.name };
      }
    },
    onError(error) {
      // console.log(error) //best way to display errors?

    },
  });

  const passwordCurrent = (changePassword || changeEmail) ?
    { passwordCurrent: Yup.string().required("Current password is required").min(6, "Your password is at least 6 characters") }
    :
    { passwordCurrent: Yup.string() };

  const password = (changePassword) ?
    {
      password: Yup.string()
        .required('New password is required')
        .min(6, "Password must be at least 6 characters")
        .max(50, "Password must be less than 50 characters")
    }
    :
    { password: Yup.string() };

  const validationSchema = Yup.object({
    email: Yup.string().required('email required').email('email address not valid'),
    name: Yup.string().required('Your name is Required'),
    ...password,
    passwordAgain: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords do not match')
      .when("password", {
        is: (password: string) => password?.length > 0,
        then: Yup.string().required("Please confirm your password")
      }),

    ...passwordCurrent,
  });

  useEffect(() => {
    if (isPasswordReset) {
      if (!appState.tempResetPassword) {
        setIsPasswordReset(false);
        return;
      }
      setChangePassword(true);
      setValuesToEdit((prev) => {
        return { ...prev, passwordCurrent: appState.tempResetPassword }
      });
    }

  }, [])


  const onSubmit = async (values: any) => {
    userUpdateSuccess.current = false;
    await updateUser({
      variables: {
        _id: values._id,
        name: values.name,
        email: values.email,
        passwordCurrent: values.passwordCurrent,
        password: values.password,
        passwordAgain: values.passwordAgain
      },
      update(cache, { data }) {

        if (!data.userUpdate) { return };

        cache.writeQuery({
          query: GET_CURRENT_USER,
          data: { userUpdate: data.userUpdate }
        });

      }
    }).then((response: any) => {
      if (response.data) {
        userUpdateSuccess.current = true;
        setUpdateResponse({ text: "Account Settings updated successfully!", colour: "green" });
        startMessageTimer();
      }
    })
      .catch((err: any) => {
        setUpdateResponse({ text: err.message, colour: "red" });
        startMessageTimer();
      });

    if (userUpdateSuccess.current) {
      setValuesToEdit((prev) => {
        return { ...prev, passwordCurrent: initialValues.passwordCurrent, password: initialValues.password, passwordAgain: initialValues.passwordAgain }
      });

      setChangePassword(false);
      setChangeEmail(false);
      setChangeUsername(false);
      setFormEdited(false);
      //Set reset to false to reenable inputs etc.
      setIsPasswordReset(false);
    }

    //Close modal from passed down setState prop
    handleClose && handleClose(false);
  }

  const handleFormChange = (e: any) => {
    setUpdateResponse({ text: "", colour: "" });
    setFormEdited(true);
    setValuesToEdit((prev) => {
      return { ...prev, [e.id]: e.value }
    });
  }

  const startMessageTimer = () => {
    setTimeout(() => {
      setUpdateResponse({ text: "", colour: "" });
    }, messageTimerDisplayTime);
  };

  const handleCancelUpdateClick = () => {
    if (changePassword) {
      setChangePassword(false);
    }
    if (changeEmail) {
      setChangeEmail(false);
    }
    if (changeUserName) {
      setChangeUsername(false);
    }
    setFormEdited(false);

    const currentPwd = isPasswordReset ? appState.tempResetPassword : initialValues.passwordCurrent;
    setValuesToEdit((prev) => {
      return { ...prev, name: emailAndUserValueBeforeCancel.current.name, email: emailAndUserValueBeforeCancel.current.email, passwordCurrent: currentPwd, password: initialValues.password, passwordAgain: initialValues.passwordAgain }
    });

  }

  const handleResetPassword = async () => {
    paswordResetLoading.current = true;
    if (valuesToEdit.email) {

      await pwdReset.resetPassword(valuesToEdit.email)
        .then((response: any) => {
          if (response.errors) {
            throw new Error(response.errors.message);
          }
          if (response.data.userResetLogin) {
            setUpdateResponse({ text: "Password reset email sent!", colour: "green" });
            startMessageTimer();

          }
        })
        .catch((err: any) => {
          setUpdateResponse({ text: err.message, colour: "red" });
          startMessageTimer();

        });
      paswordResetLoading.current = false;
      setChangePassword(false);
    }
  }

  return (
    <Formik
      initialValues={valuesToEdit}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formik: FormikProps<any>) => {
        return (
          <>
            <Form id={formId} onChange={(e) => handleFormChange(e.target)}>
              <div className="card-content">
                {/* Users name */}
                {!changeUserName && <div className='p-8 flex flex-wrap justify-between items-center'>
                  <div><h3 className="text-md font-bold text-black">Your Name:</h3></div>
                  <div><h3 className="text-md px-4 text-black">{valuesToEdit.name}</h3></div>
                  <div><button onClick={() => setChangeUsername(true)} className="button-base mt-4 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray ">Edit</button></div>
                </div>}
                {changeUserName && <div className="grid gap-6 p-8 md:grid-cols-2">
                  <FormikInput
                    label="Your Name"
                    name="name"
                    onLoadValue={valuesToEdit.name}
                    isLoading={currentUser?.loading || currentUser?.error || !currentUser?.data?.me ? true : false}
                  />
                </div>}
                {changeUserName && <div className="button-section p-4 px-6 flex justify-start items-center">
                  <div>
                    <button
                      form={formId}
                      className="button-base disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray "
                      type="submit"
                      disabled={!formEdited}
                    >
                      Save
                    </button>
                  </div>
                  <div>
                    <h2
                      onClick={handleCancelUpdateClick}
                      className="cursor-pointer ml-6 text-md hover:text-blue-800 button-table bg-red-500 hover:bg-red-300 hover:text-blue-800 text-white">
                      Cancel
                    </h2>
                  </div>
                </div>}
                {/* Email */}
                {!changeEmail && <div className='p-8 flex flex-wrap justify-between items-center'>
                  <div><h3 className="text-md font-bold text-black">Sign-in Email:</h3></div>
                  <div><h3 className="text-md px-4 text-black">{valuesToEdit.email}</h3></div>
                  <div><button onClick={() => setChangeEmail(true)} className="button-base mt-4 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray ">Change</button></div>
                </div>}
                {changeEmail && <div className='email-section grid gap-6 px-8 pb-4 md:grid-cols-2'>
                  <FormikInput
                    label="Email"
                    name="email"
                    onLoadValue={valuesToEdit.email}
                    isLoading={currentUser?.loading || currentUser?.error || !currentUser?.data?.me ? true : false}
                  />
                </div>}
                {changeEmail && <div className="button-section p-4 px-6 flex justify-start items-center">
                  <div>
                    <button
                      form={formId}
                      className="button-base disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray "
                      type="submit"
                      disabled={!formEdited}
                    >
                      Update Email
                    </button>
                  </div>
                  <div>
                    <h2
                      onClick={handleCancelUpdateClick}
                      className="cursor-pointer ml-6 text-md hover:text-blue-800 button-table bg-red-500 hover:bg-red-300 hover:text-blue-800 text-white">
                      Cancel
                    </h2>
                  </div>
                </div>
                }
                {/* Current Password */}
                {(changePassword || changeEmail) && <div className='current-password-section grid gap-6 px-8 md:grid-cols-2'>
                  <FormikInput
                    label="Current password *"
                    name="passwordCurrent"
                    isPassword={true}
                    onLoadValue={valuesToEdit.passwordCurrent}
                    isLoading={isPasswordReset || currentUser?.loading || currentUser?.error || !currentUser?.data?.me ? true : false}
                  />
                </div>}
                {isPasswordReset && <h2 className="text-md px-4 text-black bg-green-300 mt-2 px-8 py-2">Temporary "Current password" autoloaded successfully.</h2>}
                {/* Password */}
                {!changePassword && <div className=' p-8 flex flex-wrap justify-between items-center'>
                  <div><h3 className="text-md font-bold text-black">Password:</h3></div>
                  <div><h3 className="text-xl px-4 text-black">********</h3></div>
                  <div><button onClick={() => setChangePassword(true)} className="button-base mt-4 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray ">Reset Password</button></div>
                </div>}
                {changePassword && <>
                  <div className='new-password-section grid gap-6 p-8 md:grid-cols-2'>
                    <FormikInput
                      label="Create new Password *"
                      name="password"
                      isPassword={true}
                      onLoadValue={valuesToEdit.password}
                      isLoading={currentUser?.loading || currentUser?.error || !currentUser?.data?.me ? true : false}
                    />
                    <FormikInput
                      label="Confirm new password *"
                      name="passwordAgain"
                      isPassword={true}
                      onLoadValue={valuesToEdit.passwordAgain}
                      isLoading={currentUser?.loading || currentUser?.error || !currentUser?.data?.me ? true : false}
                    />
                  </div>
                  {changePassword && <>
                    <div className="button-section p-4 px-6 flex justify-start items-center">
                      <div>
                        <button
                          form={formId}
                          className="button-base disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray "
                          type="submit"
                          disabled={!formEdited}
                        >
                          Update Password
                        </button>
                      </div>
                      <div>
                        <h2
                          onClick={handleCancelUpdateClick}
                          className="cursor-pointer ml-6 text-md hover:text-blue-800 button-table bg-red-500 hover:bg-red-300 hover:text-blue-800 text-white">
                          Cancel
                        </h2>
                      </div>
                    </div>
                  </>}
                </>
                }
                {updateResponse.text && <div className={`w-full text-lg mb-4 bg-slate-200 flex flex-col items-center align-center`}>
                  <h1 style={{ color: updateResponse.colour }} className='p-2'>{updateResponse.text}</h1>
                </div>}
              </div>
            </Form>
            {changePassword && <div className='p-8 flex justify-start items-center'>
              <div><h3 className="text-md text-black">Forgot your current password?</h3></div>
              <div>
                <button onClick={handleResetPassword}
                  disabled={paswordResetLoading.current}
                  className="button-base ml-6 py-2 px-4 bg-sky-50 border border-solid border-black rounded-xl border-current border-black disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray ">
                  {paswordResetLoading.current ? "Sending reset email..." : "Send reset email"}
                </button>
              </div>
            </div>}
          </>
        )
      }}
    </Formik>
  )
}

export default UserInput
