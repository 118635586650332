import { gql } from '@apollo/client'

// **** INVOICE RELATED MUTATIONS ****

//This returns a meterCode for Admin dash meterCode panel
export const UPDATE_CUSTOMER = gql`
   mutation updateInvoiceCustomer ($input: customerChangeInput!) {
    updateInvoiceCustomer(input: $input) {
      customer
    }
  }
`


