import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_ALL_FARMS } from '../../queriesAndMutations/queries/farm.queries'
import Modal from '../../components/modals/Modal'
import FarmCreateInput from '../../components/graphQLInputs/createInputs/FarmCreateInput'
import ModalDeleteConfirmation from '../../components/modals/ModalDeleteConfirmation'
import { IMessage, messageTimerDisplayTime } from '../../globals'

type Props = {}

const SettingsFarms: React.FC<Props> = () => {
  const [farmId, setFarmId] = useState<string>("");
  const [farmName, setFarmName] = useState<string>("");
  const [size, setSize] = useState<string>();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [updateResponse, setUpdateResponse] = useState<IMessage>({ text: "", colour: "red" });

  //GraphQL
  const allFarms = useQuery<any>(GET_ALL_FARMS);

  const handleEdit = (id: string, farmName: string, size: string) => {
    setFarmId(id);
    setFarmName(farmName);
    setSize(size);
    setShowModal(true);
  }

  const handleDelete = (id: string, farmToDelete: string) => {
    setFarmId(id);
    setFarmName(farmToDelete);
    setShowDeleteModal(true);
  }


  const startMessageTimer = () => {
    setTimeout(() => {
      setUpdateResponse && setUpdateResponse({ text: "", colour: "" });
    }, messageTimerDisplayTime);
  };

  useEffect(() => {
    if(updateResponse.text){
      if( !updateResponse.text.includes("Please wait...")){
        startMessageTimer();
      }
    }
  }, [updateResponse])




  return (
    <section className="grid gap-6 my-12 lg:grid-cols-3 md:fluid-container">

      <div className="pl-6 space-y-2 md:pl-0">
        <h3 className="text-2xl font-bold text-primary ">Farms Setup</h3>
      </div>

      <div className="max-w-4xl col-span-2 space-y-8">
        <div className="divide-y-2 card divide-primary/10">
          <div className="p-4 px-6 card-topper ">
            <h3 className="text-xl font-bold text-primary">Farms List</h3>
          </div>
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-flex min-w-full">
                <div className="w-full overflow-hidden">
                  <table className="min-w-full farms-table">
                    <thead className="border-b-2 border-secondary/2">
                      <tr>
                        <th scope="col">Farm Name</th>
                        <th scope="col">Farm Size</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {allFarms.loading && (
                        <tr>
                          <td>Loading Data...</td>
                        </tr>
                      )}
                      {!allFarms.loading && !allFarms?.data?.farms?.length && (
                        <tr>
                          <td>No Farms to display. Add a new Farm below...</td>
                        </tr>
                      )}
                      {!allFarms.loading && allFarms?.data?.farms?.map((farm: any) => {
                        return <tr key={farm._id} className="odd:bg-secondary/5 hover:bg-secondary/10">
                          <td>
                            {farm?.name}
                          </td>
                          <td>
                            {farm?.size} ha
                          </td>
                          <td className="px-6 py-1 text-sm font-light text-right text-gray-900 whitespace-nowrap">
                            <button onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleEdit(farm?._id, farm?.name, farm?.size)} className="button-table mr-2">Edit</button>
                            <button onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleDelete(farm?._id, farm?.name)} className="button-table button-table-delete">
                              Delete
                            </button>
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                  {updateResponse.text && <div className={`w-full text-lg my-4 bg-slate-200 flex flex-col items-center align-center`}>
                          <h1 style={{ color: updateResponse.colour }} className='p-2'>{updateResponse.text}</h1>
                        </div>}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-4xl divide-y-2 card divide-primary/10">
          <FarmCreateInput formId='new-farm' editMode={false} setUpdateResponse={setUpdateResponse} />
        </div>
      </div>
      {showDeleteModal &&
        <Modal isOpen={showDeleteModal} onClose={setShowDeleteModal}>
          <ModalDeleteConfirmation toDeleteName={farmName} _id={farmId} deleteFarm onClose={setShowDeleteModal} setUpdateResponse={setUpdateResponse} />
        </Modal>}
      {showModal &&
        <Modal isOpen={showModal} onClose={setShowModal}>
          <FarmCreateInput formId='edit-farms' farmName={farmName} farmSize={size} farmToEditId={farmId} editMode={true} setUpdateResponse={setUpdateResponse} />
        </Modal>}
    </section>
  )
}

export default SettingsFarms