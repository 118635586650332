import { gql } from '@apollo/client'


// **** ALERT RELATED MUTATIONS ****

export const ADD_NEW_ALERT = gql`
  mutation addNewAlert(
    $meterId: String!
    $alertSpec: AlertSpec!
    $volume: Float!
  ) {
    alertCreate( input: { meterId: $meterId alertSpec: $alertSpec volume: $volume }) {
        _id
    	  meter {_id name meterCode}
    	  farm {_id name }
     	  alertSpec
    	  volume
    }
  }
`

export const UPDATE_ALERT = gql`
  mutation updateAlert(
    $_id: String!
    $meterId: String!
    $alertSpec: AlertSpec!
    $volume: Float!
  ) {
    alertUpdate(
      input: {
        _id: $_id
        meterId: $meterId
        alertSpec: $alertSpec
        volume: $volume
      }
    ) {
      _id
      meter {
        _id
        name
        meterCode
      }
      farm {
        _id
        name
      }
      alertSpec
      volume
    }
  }
`

export const DELETE_ALERT = gql`
  mutation deleteAlert($_id: String!) {
    alertDelete(_id: $_id)
  }
`
export const RESET_ALERT_HISTORY_NOTIFICATIONS = gql`
  mutation alertHistoryAlertCountReset{
  alertHistoryAlertCountReset 
}
`
