import { OperationVariables, QueryResult} from '@apollo/client';
import { useEffect, useState } from 'react';
import { Farm, Meter } from '../../utils/typedefs';

type Props = {
  allMeters: QueryResult<any, OperationVariables>;
  allFarms: QueryResult<any, OperationVariables>;
  getDeviceSamplesCallback: (meter: Meter) => void;
}

const MeterSidebar: React.FC<Props> = ({ allFarms, allMeters, getDeviceSamplesCallback }) => {

  const [currentlySelectedMenuItem, setCurrentlySelectedMenuItem] = useState<number>(0)

  const handleMeterNameClick = (meter: Meter, menuItemIndex: number) => {
    setCurrentlySelectedMenuItem(menuItemIndex);
    getDeviceSamplesCallback(meter);
  }

   useEffect(() => {
    //auto load data of first meter
    if (allMeters?.data) {
      getDeviceSamplesCallback(allMeters?.data?.meters[0]);
    }

  }, [])

  return (
    <nav className=" text-primary">
      {!allFarms.loading && allFarms?.data?.farms?.map((farm: Farm, index: number) => {
        return <ul key={index} className="flex flex-col space-y-2 mb-8">
          <h3 className="mb-1 font-bold">{farm.name}</h3>
          {!allMeters.loading && allMeters?.data?.meters?.map((meter: Meter, idx: number) => {
            if (meter.farm.name === farm.name) {
              return <li key={idx}><a 
              onClick={() => handleMeterNameClick( meter, idx )} 
              style={{background: idx === currentlySelectedMenuItem ? "#97dbf1" : ""}}
              className="inline-flex w-full cursor-pointer py-2 px-4 transition rounded-lg hover:bg-secondary/20"
              >
                {meter.name}
                </a>
                </li>
            }
          })}

        </ul>
      })}
    </nav>

  )
}

export default MeterSidebar
