import { ErrorMessage, Field } from 'formik'
import { string } from 'yup';

type Props = {
  name: string;
  label: string;
  dropDownOptions?: any; 
  unit?: string;
  meterFarmCombine?: boolean;
  onLoadDropdownValue?: string;
}

const FormikSelect: React.FC<Props> = ({ name, label, unit, onLoadDropdownValue, meterFarmCombine, dropDownOptions, ...rest }) => {
  return (
    <div className="form-control inline-block ">
      <label className="flex flex-col grow ">
        <span className="pb-1 text-gray-700 font-bold">{label}</span>
        <Field
          className="block pr-4 transition border-2 border-gray-300 rounded-md shadow-sm hover:bg-secondary/10 focus:border-secondary focus:ring focus:ring-secondary/50 focus:ring-opacity-50 bg-secondary/5"
          as={"select"}
          id={name}
          name={name}
          value={onLoadDropdownValue}
          {...rest}
        >
          <option>{dropDownOptions ? "Select one..." : "Loading..."}</option>
          {dropDownOptions?.map((option: any) => {
              try {
                return (
                    <option key={option._id} value={option?._id} >
                      {meterFarmCombine ? `${option?.name} (${option?.farm?.name} )` : option?.name}
                      </option>)
              } catch (error: any) {
                return (<option key={Math.random()} value={error.message} >
                  Error: {error.message}
                </option>
                )
              }
            })
          }
        </Field>
      </label>
      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-xs"
      />
    </div>
  )
}

export default FormikSelect
