
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react'
import { LineChart } from '../../../components/Charts'
import 'chartjs-adapter-moment';
import { appTimeZone } from '../../../globals';
import { defaultTitle, IChartDataSet, initialChartDataSet, sevenDailyChartOptions } from './chartCard.globals';

type Props = {
  sevenDailyMeterData: any;
  sevenDailyMeterError: any;
  isLoading: boolean;
  timestamps: Date[];
}


const SevenDailyChartCard: React.FC<Props> = ({ sevenDailyMeterData, isLoading, timestamps, sevenDailyMeterError }) => {
  const [sevenDailyChartData, setSevenDailyChartDataChartData] = useState<IChartDataSet>(initialChartDataSet);
  const LPERMIN_TO_M3PERH = 0.06;

  let todayChartPoints: [{ x: Date, y: number }];
  let day6ChartPoints: [{ x: Date, y: number }];
  let day5ChartPoints: [{ x: Date, y: number }];
  let day4ChartPoints: [{ x: Date, y: number }];
  let day3ChartPoints: [{ x: Date, y: number }];
  let day2ChartPoints: [{ x: Date, y: number }];
  let day1ChartPoints: [{ x: Date, y: number }];



  useEffect(() => {
    if (!sevenDailyMeterData?.meterData?.length) { return };

    try {


      setSevenDailyChartDataChartData((prev: any) => {
        return {
          ...prev,
          datasets: [

            {
              label: moment().subtract(6, 'day').format("MMM DD"),
              data: day1ChartPoints ? [...day1ChartPoints] : [],
              backgroundColor: '#82C3FD',
              borderColor: '#82C3FD',
              fill: false,
              lineTension: 0,
              radius: 1.0,
            },
            
            {
              label: moment().subtract(5, 'day').format("MMM DD"),
              data: day2ChartPoints? [...day2ChartPoints] : [],
              backgroundColor: '#0486FB',
              borderColor: '#0486FB',
              fill: false,
              lineTension: 0,
              radius: 1.0,
            },
            {
              label: moment().subtract(4, 'day').format("MMM DD"),
              data: day3ChartPoints ? [...day3ChartPoints] : [],
              backgroundColor: '#2a86d9',
              borderColor: '#2a86d9',
              fill: false,
              lineTension: 0,
              radius: 1.0,
            },
            {
              label: moment().subtract(3, 'day').format("MMM DD"),
              data: day4ChartPoints ? [...day4ChartPoints]  : [],
              backgroundColor: '#046BC9',
              borderColor: '#046BC9',
              fill: false,
              lineTension: 0,
              radius: 1.0,
            },

            {
              label: moment().subtract(2, 'day').format("MMM DD"),
              data: day5ChartPoints ? [...day5ChartPoints]  : [],
              backgroundColor: '#045196',
              borderColor: '#045196',
              fill: false,
              lineTension: 0,
              radius: 1.0,
            },
            {
              label: moment().subtract(1, 'day').format("MMM DD"),
              data: day6ChartPoints ? [...day6ChartPoints]  : [],
              borderColor: '#01315b',
              backgroundColor: '#01315b',
              fill: false,
              lineTension: 0,
              radius: 1.0,
            },
            {
              label: moment().format("MMM DD"),
              data: todayChartPoints ? [...todayChartPoints] : [],
              borderColor: '#011B32',
              backgroundColor: '#011B32',
              fill: false,
              lineTension: 0,
              radius: 1.0,

            },
          ],
        }
      });
    } catch (error) {
      console.log("setSevenDailyChartDataChartData: " + error)

    }
  }, [sevenDailyMeterData])

  const getMaxY = (inputArray: [{ x: Date, y: number }]): number => {
    let y: any = -Infinity;
    for (let p of inputArray) {
      if (y < p.y) y = p.y;

    }
    return y;
  };


  const sevenDailyChartConfigOptions = () => {
    try {
      if (!sevenDailyMeterData?.meterData?.length) { return };


      todayChartPoints = sevenDailyMeterData?.meterData[sevenDailyMeterData?.meterData?.length - 1]?.samples.map((point: any) => ({
        x: moment.tz(point.timestamp, appTimeZone).format(' DD MMM YYYY HH:mm'),
        y: point.delta / point.timeDelta * LPERMIN_TO_M3PERH
      }));

      day6ChartPoints = sevenDailyMeterData?.meterData[sevenDailyMeterData?.meterData?.length - 2]?.samples.map((point: any) => ({
        x: moment.tz(point.timestamp, appTimeZone).format(' DD MMM YYYY HH:mm'),
        y: point.delta / point.timeDelta * LPERMIN_TO_M3PERH
      }));
      day5ChartPoints = sevenDailyMeterData?.meterData[sevenDailyMeterData?.meterData?.length - 3]?.samples.map((point: any) => ({
        x: moment.tz(point.timestamp, appTimeZone).format(' DD MMM YYYY HH:mm'),
        y: point.delta / point.timeDelta * LPERMIN_TO_M3PERH
      }));
      day4ChartPoints = sevenDailyMeterData?.meterData[sevenDailyMeterData?.meterData?.length - 4]?.samples.map((point: any) => ({
        x: moment.tz(point.timestamp, appTimeZone).format(' DD MMM YYYY HH:mm'),
        y: point.delta / point.timeDelta * LPERMIN_TO_M3PERH
      }));
      day3ChartPoints = sevenDailyMeterData?.meterData[sevenDailyMeterData?.meterData?.length - 5]?.samples.map((point: any) => ({
        x: moment.tz(point.timestamp, appTimeZone).format(' DD MMM YYYY HH:mm'),
        y: point.delta / point.timeDelta * LPERMIN_TO_M3PERH
      }));
      day2ChartPoints = sevenDailyMeterData?.meterData[sevenDailyMeterData?.meterData?.length - 6]?.samples.map((point: any) => ({
        x: moment.tz(point.timestamp, appTimeZone).format(' DD MMM YYYY HH:mm'),
        y: point.delta / point.timeDelta * LPERMIN_TO_M3PERH
      }));

      day1ChartPoints = sevenDailyMeterData?.meterData[0]?.samples.map((point: any) => ({
        x: moment.tz(point.timestamp, appTimeZone).format(' DD MMM YYYY HH:mm'),
        y: point.delta / point.timeDelta * LPERMIN_TO_M3PERH
      }));



      let sevenDailyDatasetOptions = Object.assign({}, sevenDailyChartOptions);

      return {
        ...sevenDailyDatasetOptions,
        ...sevenDailyChartOptions,

        scales: {
          y: {
            ...defaultTitle.y,
          },
          x: {
            ...defaultTitle.x,
            type: 'time',

          }
        },
      }
    } catch (error) {
      console.log(error)

    }

  }



  const sevenDailyConfigOptions = sevenDailyChartConfigOptions();





  return (
    <div className="py-12 space-y-8">
      {/* DAILY CHART */}
      <section className="">
        <div className="flex flex-row items-center justify-between px-4 pb-2 md:px-0">
          <h2 className="text-xl font-bold text-primary">7 Daily</h2>
          <div className="text-sm opacity-50 text-primary">{`${moment().subtract(6, 'days').format('DD MMM')} - ${moment().format('DD MMM')}`}</div>
        </div>
        <div className="w-full card">
          <div className="flex flex-row items-end p-4 border-b text-primary border-primary/25">


            {isLoading && <span className="text-xl font-bold text-green-800">Loading 7 Daily chart data...</span>}
            {!sevenDailyMeterData?.meterData?.length && !sevenDailyMeterError && !isLoading && <span className="text-xl font-bold text-red-800">No samples found.</span>}

            {sevenDailyMeterError && <span className="text-xl font-bold text-green-800">{sevenDailyMeterError.message}</span>}

          </div>
          <div className="relative w-full p-4 h-100">
            {sevenDailyMeterData && <LineChart chartConfigOptions={sevenDailyConfigOptions} chartData={isLoading ? initialChartDataSet : sevenDailyChartData} />}
          </div>
        </div>
      </section>
    </div>
  )
}

export default SevenDailyChartCard