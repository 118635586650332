import React from 'react'
import { useLocation } from 'react-router-dom';
import UserInput from '../../components/graphQLInputs/userInputs/UserInput'


const SettingsAccount= ( ) => {
  const reset: string | null = new URLSearchParams(useLocation().search).get("reset")
  
  return (
    
    <section className="grid gap-6 my-12 lg:grid-cols-3 md:fluid-container">

    <div className="pl-6 space-y-2 md:pl-0">
      <h3 className="text-2xl font-bold text-primary ">Account Settings</h3>
    </div>

    <div className="max-w-4xl col-span-2 divide-y-2 card divide-primary/10">
   
        <UserInput reset={reset === "true" ? true : false} formId={"user-edit"} /> 
        
    </div>
    <div className="max-w-4xl divide-y-2 card divide-primary/10">
    
    </div>

  </section>
  )
}

export default SettingsAccount