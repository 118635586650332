import React, { useEffect, useState } from 'react'
import {  useQuery } from '@apollo/client'
import { GET_ALL_METERS } from '../../queriesAndMutations/queries/meter.queries'
import Modal from '../../components/modals/Modal'
import MeterCreateInput from '../../components/graphQLInputs/createInputs/MeterCreateInput'
import ModalDeleteConfirmation from '../../components/modals/ModalDeleteConfirmation'
import { IMessage, messageTimerDisplayTime } from '../../globals'
import ReadingCreateInput from '../../components/graphQLInputs/createInputs/ReadingCreateInput'


type Props = {}

const SettingsMeters: React.FC<Props> = () => {
  const [meterId, setMeterId] = useState<string>("");
  const [farmId, setFarmId] = useState<string>("");
  const [farmName, setFarmName] = useState<string>("");
  const [meterCode, setMeterCode] = useState<string>("");
  const [meterNumber, setMeterNumber] = useState<string>("");
  const [meterName, setMeterName] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showReadingModal, setShowReadingModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [updateResponse, setUpdateResponse] = useState<IMessage>({ text: "", colour: "red" });


  //GraphQL
  const allMeters = useQuery<any>(GET_ALL_METERS);

  const handleAddMeterReading = ( _farmName: string, _meterName: string, _meterNumber: string) => {
    setShowReadingModal(true);
    setFarmName(_farmName);
    setMeterName(_meterName);
    setMeterNumber(_meterNumber);
  }

  const handleEdit = (id: string, _farmId: string, _farmName: string, _meterName: string, _meterCode: string) => {
    setShowModal(true);
    setMeterId(id);
    setFarmId(_farmId);
    setFarmName(_farmName);
    setMeterName(_meterName);
    setMeterCode(_meterCode);
  }

  const handleDelete = (id: string, metertoDelete: string) => {
    setMeterId(id);
    setMeterName(metertoDelete);
    setShowDeleteModal(true);
  }

  const startMessageTimer = () => {
    setTimeout(() => {
      setUpdateResponse && setUpdateResponse({ text: "", colour: "" });
    }, messageTimerDisplayTime);
  };

  useEffect(() => {
    if(updateResponse.text){
      if( !updateResponse.text.includes("Please wait...")){
        startMessageTimer();
      }
    }
  }, [updateResponse])
  


  return (
    <section className="grid gap-6 my-32 lg:grid-cols-3 md:fluid-container">

      <div className="pl-6 space-y-2 md:pl-0">
        <h3 className="text-2xl font-bold text-primary ">Meter Setup</h3>
        <div className="prose-sm prose opacity-70">
          <p>Your MeterCode will be a 6-7 digit code provided by your Cold Creek account manager.</p>
        </div>
      </div>

      <div className="min-w-full col-span-2 space-y-8">
        <div className="divide-y-2 card divide-primary/10">
          <div className="p-4 px-6 card-topper ">
            <h3 className="text-xl font-bold text-primary">Meter List</h3>
          </div>
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-flex min-w-full">
                <div className="w-full overflow-hidden">
                  <table className="min-w-full meters-table">
                    <thead className="border-b-2 border-secondary/2">
                      <tr>
                        <th scope="col">Meter Name</th>
                        <th scope="col">Farm Name</th>
                        <th scope="col">MeterCode</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {allMeters.loading && (
                        <tr>
                          <td>Loading Data...</td>
                        </tr>
                      )}
                      {!allMeters.loading && !allMeters?.data?.meters?.length && (
                        <tr>
                          <td>No Meters to display. Add a new Meter below...</td>
                        </tr>
                      )}
                      {!allMeters.loading && allMeters?.data?.meters?.map((meter: any) => {
                        return <tr key={meter._id} className="odd:bg-secondary/5 hover:bg-secondary/10">
                          <td>
                            {meter?.name}
                          </td>
                          <td>
                            {meter?.farm?.name}
                          </td>
                          <td>
                            ID: {meter?.meterCode}
                          </td>
                          <td className="px-6 py-4 text-sm font-light text-center text-gray-900 whitespace-nowrap">
                            <button onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleAddMeterReading(meter?.farm?.name, meter?.name, meter?.meterNumber)} className="button-table mr-2">Add Reading</button>
                            <button onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleEdit(meter?._id, meter?.farm?._id, meter?.farm?.name,  meter?.name, meter?.meterCode)} className="button-table mr-2">Edit</button>
                            <button onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleDelete(meter?._id, meter?.name)} className="button-table button-table-delete">
                              Delete
                            </button>
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                  {updateResponse.text && <div className={`w-full text-lg my-4 bg-slate-200 flex flex-col items-center align-center`}>
                          <h1 style={{ color: updateResponse.colour }} className='p-2'>{updateResponse.text}</h1>
                        </div>}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="min-w-full divide-y-2 card divide-primary/10">
          <MeterCreateInput formId='new-meter' editMode={false} setUpdateResponse={setUpdateResponse} />
        </div>
      </div>
      {showDeleteModal &&
        <Modal isOpen={showDeleteModal} onClose={setShowDeleteModal}>
          <ModalDeleteConfirmation toDeleteName={meterName} _id={meterId} deleteMeter onClose={setShowDeleteModal} setUpdateResponse={setUpdateResponse}  />
        </Modal>}
      {showModal &&
        <Modal isOpen={showModal} onClose={setShowModal}>
          <MeterCreateInput formId='edit-meter' meterName={meterName} farmId={farmId} farmName={farmName} meterCode={meterCode} editMode={true} meterToEditId={meterId} setUpdateResponse={setUpdateResponse} />
        </Modal>}
      {showReadingModal &&
        <Modal isOpen={showReadingModal} onClose={setShowReadingModal}>
          <ReadingCreateInput isAdminDash={false} formId='add-reading' farmName={farmName}  meterNumber={meterNumber} setUpdateResponse={setUpdateResponse} />
        </Modal>}
    </section>
  )
}

export default SettingsMeters
