import { gql } from '@apollo/client'

// **** METER READING RELATED MUTATIONS ****

export const ADD_NEW_METER_READING = gql`
   mutation readingCreate ($input: ReadingCreateInput!) {
    readingCreate(input: $input) {
        _id
      meterNumber
      readingValue
      readingDatetime
    }
  }
`
