import { gql } from '@apollo/client'

// **** METER RELATED MUTATIONS ****

export const ADD_NEW_METER = gql`
  mutation addNewMeter(
    $farmId: String!
    $name: String!
    $meterCode: String!
  ) {
    meterCreate( input: { farmId: $farmId name: $name meterCode: $meterCode }) {
       _id
      farm { _id name }
      name
      meterCode
      meterNumber
      deviceEUI
      lastWebhookReceivedAt
  }
  }
`
export const REGISTER_METER_AND_CUSTOMER = gql`
  mutation registerMeterAndCustomer($input: RegisterMeterCreateInput!) {
    meterAndCustomerRegister( input: $input) 
  }
`

export const UPDATE_METER = gql`
  mutation updateMeter(
    $_id: String!
    $farmId: String!
    $name: String!
    $meterCode: String!
  ) {
    meterUpdate( input: { _id: $_id farmId: $farmId name: $name meterCode: $meterCode }) {
       _id
    	farm {_id name}
    	name
      meterCode
      meterNumber
      deviceEUI
      lastWebhookReceivedAt
  }
  }
`

export const DELETE_METER = gql`
  mutation deleteMeter($_id: String!) {
    meterDelete(_id: $_id)
  }
`


export const UPDATE_DEVICE_EUI = gql`
   mutation updateMeterDeviceEUI ($input: deviceEUIChangeInput!) {
    updateMeterDeviceEUI(input: $input) {
      deviceEUI
    }
  }
`

