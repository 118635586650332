import { useState } from 'react'
import {
  Formik,
  Form,
  FormikProps,
} from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import FormikInput from '../../components/fields/FormikInput'

// Import GraphQL queries and mutations
import ResetPasswordConfirmation from './ResetPasswordConfirmation'
import { usePasswordReset } from '../../hooks/usePasswordReset'


interface IResetValue {
  email: string;
}

const initialResetValue: IResetValue = {
  email: ''
}

const ResetPassword = () => {

  const [isResetting, setIsResetting] = useState<boolean>(false);
  const [resetSucceeded, setResetSucceeded] = useState<boolean>(false);
  const [resetErrors, setResetErrors] = useState<string>("");
  const [valuesToEdit, setValuesToEdit] = useState<IResetValue>(initialResetValue);
  const pwdReset = usePasswordReset();
  // const [userResetLogin] = useMutation<any>(PASSWORD_RESET);

  const validate = Yup.object({
    email: Yup.string().email('Email is invalid').required('')
  });


  const onSubmit = async (values: any) => {
    setIsResetting(true);

     pwdReset.resetPassword(values.email)
      .then((response: any) => {
        if(response.errors){
          console.log(response.errors.message)
         throw new Error(response.errors.message);
        }
        if (response.data.userResetLogin) {
          console.log(response.data.userResetLogin)
          setResetSucceeded(true);
          setIsResetting(false);
        }
      })
      .catch((err: any) => {
        setResetErrors(err.message);
        setIsResetting(false);
        setResetSucceeded(false);
      });
  }

  const handleFormChange = (e: any) => {
    setResetErrors("");
    setValuesToEdit((prev) => {
      return { ...prev, [e.id]: e.value }
    });
  }

  return (
    resetSucceeded ? <ResetPasswordConfirmation /> :
      <Formik
        initialValues={initialResetValue}
        validationSchema={validate}
        onSubmit={onSubmit}
      >
        {(formik: FormikProps<any>) => {
          return (
            <>
              <Form className="relative" id={"login"} onChange={(e) => handleFormChange(e.target)}>
                <img alt=''
                  src="/assets/bg.jpg"
                  className="absolute inset-0 object-cover w-full h-full saturate-0"
                />
                <div className="absolute inset-0 block w-full h-full bg-primary/80"></div>

                <div className="relative z-10 flex flex-col items-center justify-center min-h-screen mx-auto">
                  <div className="divide-y-2 w-100 card divide-primary/20 rounded-2xl bg-shadow-2xl">
                    <div className="bg-primary">
                      <img
                        alt='logo-primary'
                        src="/assets/logo-primary.svg"
                        className="w-3/4 p-6 mx-auto"
                      />
                    </div>
                    <div className="bg-secondary">
                      <h1 className="p-3 text-2xl font-bold text-center text-white">
                        Reset your password
                      </h1>
                    </div>
                    <div className="flex flex-col p-6 space-y-6">
                      <FormikInput
                        label="Email"
                        name="email"
                        isLoading={false}
                      />
                    </div>
                    <div className="p-6 py-4">
                      <div className="errors mb-6 text-red-700">{resetErrors && <h1>{resetErrors}</h1>}</div>
                      <button type='submit' disabled={isResetting} className="w-full button-base disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray">{isResetting ? "Please wait..." : "Reset Password"}</button>
                    </div>
                  </div>
                  <Link className="p-3 text-white/50 hover:text-accent" to="/login">
                    Already registered? / Login here
                  </Link>
                  <Link className="p-3 text-white/50 hover:text-accent" to="/signup">
                    Not registered? / Signup here
                  </Link>
                </div>
              </Form>
            </>
          )
        }}
      </Formik>
  )
}

export default ResetPassword
