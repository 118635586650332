import React, { useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import { DELETE_FARM } from '../../queriesAndMutations/mutations/farm.mutations'
import { DELETE_METER } from '../../queriesAndMutations/mutations/meter.mutations'
import { DELETE_ALERT } from '../../queriesAndMutations/mutations/alert.mutations'
import { GET_ALERT_HISTORY } from '../../queriesAndMutations/queries/alert.queries'
import { IMessage } from '../../globals'



type Props = {
  _id?: string;
  toDeleteName?: string;
  deleteMeter?: boolean;
  deleteFarm?: boolean;
  deleteAlert?: boolean;
  deleteMeterCode?: boolean;
  alertVolume?: number;
  alertSpec?: string;
  uniqueId?: string;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateResponse: React.Dispatch<React.SetStateAction<IMessage>>;

}

type InitialValues = {
  _id: string;
  toDeleteName: string;
  deleteMeter: boolean;
  deleteFarm: boolean;
  deleteAlert: boolean;
  deleteMeterCode?: boolean;
  alertVolume: number;
  alertSpec: string;
  uniqueId: string;
}

const initialValues: InitialValues = {
  _id: '',
  toDeleteName: '',
  deleteMeter: false,
  deleteFarm: false,
  deleteAlert: false,
  deleteMeterCode: false,
  alertVolume: 0,
  alertSpec: '',
  uniqueId: ''
}

const ModalDeleteConfirmation = ({ toDeleteName, _id, alertSpec, alertVolume, deleteMeterCode, uniqueId, deleteAlert, deleteFarm, deleteMeter, onClose, setUpdateResponse }: Props) => {

  const thingBeingDeleted = useRef<string>("");

  const [deleteFarmFromDB] = useMutation<any>(DELETE_FARM);
  const [deleteMeterFromDB] = useMutation<any>(DELETE_METER);
  const [deleteAlertFromDB] = useMutation<any>(DELETE_ALERT,
    {
      refetchQueries: [
        {
          query: GET_ALERT_HISTORY,
        },
      ],
    });


  const [deleteError, setDeleteError] = useState<string>("");


  const handleDelete = async () => {
    try {
      setDeleteError("");
      let result;
      if (deleteFarm) {
        thingBeingDeleted.current = "Farm"
        result = await deleteFarmFromDB({
          variables: {
            _id
          }, update(cache, { data }) {
            if (!data) return;

            const id = cache.identify({
              __typename: "Farm",
              id: _id
            });

            cache.evict({ id });
            cache.gc();
          }
        }).then((result: any)=>{
          return result?.data?.farmDelete;
        });
      }
      if (deleteMeter) {
        thingBeingDeleted.current = "Meter"
        result = await deleteMeterFromDB({
          variables: {
            _id
          }, update(cache, { data }) {
            if (!data) return;

            const id = cache.identify({
              __typename: "Meter",
              id: _id
            });

            cache.evict({ id });
            cache.gc();
          }
        }).then((result: any)=>{
          return result?.data?.meterDelete;
        })
      }
      if (deleteAlert) {
        thingBeingDeleted.current = "Alert"
        result = await deleteAlertFromDB({
          variables: {
            _id
          }, update(cache, { data }) {
            if (!data) return;

            const id = cache.identify({
              __typename: "Alert",
              id: _id
            });

            cache.evict({ id });
            cache.gc();
          }
        }).then((result: any)=>{
          return result?.data?.alertDelete;
        })
      }

     

      if (result) {

        setUpdateResponse && setUpdateResponse({ text: `${thingBeingDeleted.current} deleted successfully!`, colour: "red" })
        onClose && onClose(false);
      } else {
        setDeleteError(`Error: ${toDeleteName} couldn't be deleted!`)
      }
    } catch (error) {
      setDeleteError(`Error: ${error}`)
    }

  };


  return (

    <div className="w-full my-12 card divide-primary/10">
      <div className="p-4 px-6 card-topper ">
        <div className="p-2 flex flex-col items-center justify-center">

          {deleteMeterCode && <> <h3 className="text-lg text-center font-bold text-red-500 my-2">Are you sure you want to delete this MeterCode item?</h3>
            <div className="text-left mt-2 mb-6">
              <h4 className="text-md font-bold text-black ">Meter Number: {toDeleteName}</h4>
              <h4 className="text-md font-bold text-black ">Unique ID: {uniqueId}</h4>
            </div>
          </>
          }
          {deleteAlert && <>
            <h3 className="text-lg text-center font-bold text-red-500 my-2">Are you sure you want to delete this Alert?</h3>
            <div className="text-left mt-2 mb-6">
              <h4 className="text-md font-bold text-black ">Meter: {toDeleteName}</h4>
              <h4 className="text-md font-bold text-black ">Alert Spec: {alertSpec === "AM2" ? "2AM" : "All Day"}</h4>
              <h4 className="text-md font-bold text-black ">Usage Volume: {alertVolume} m3</h4>
            </div>
          </>
          }

          {!deleteAlert && !deleteMeterCode &&
            <h3 className="text-lg text-center font-bold text-red-500 my-6">Are you sure you want to delete {deleteAlert && "Alert:"} {deleteFarm && "Farm:"} {deleteMeter && "Meter:"} {toDeleteName}?</h3>}

          {!deleteMeterCode && <h3 className="text-md text-center font-bold text-black mb-4">{`This will also delete any associated ${deleteFarm ? "Meters, " : ""}${deleteFarm || deleteMeter ? "Alerts and " : ""}Alert History.`}</h3>}
          <button
            onClick={handleDelete}
            className="button-table bg-red-500 hover:bg-red-300 hover:text-blue-800 text-white mr-auto " type="submit" >
            Yes, Delete {deleteAlert && "Alert:"} {deleteFarm && "Farm:"} {deleteMeter && "Meter:"} {deleteMeterCode && "Meter Number:"} {toDeleteName}
          </button>
          {deleteError && <h5 className="mt-4 text-md text-red-700 mb-4">{deleteError}</h5>}
        </div>
      </div>
    </div>

  )
}

export default ModalDeleteConfirmation
