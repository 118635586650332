import { ErrorMessage } from 'formik'
import { ReactComponent as LoadingSpinner } from '../../assets/loading.svg';

type Props = {
  suggestions: Array<{}>;
  showSuggestions: boolean;
  onSelected: any
  isLoading: boolean
  children?: React.ReactNode
}

const AutoCompleteList: React.FC<Props> = ({ showSuggestions, suggestions, onSelected, isLoading, children }) => {
  return (
    <>

      <div className="flex flex-col items-center">
        { children }
        { isLoading && <> <h5>Searching...</h5> <LoadingSpinner className=" w-1/5 p-2 " /></>}
        { showSuggestions && <div className="relative border-solid border-2 py-1 w-full">
          <ul className='list-none'>
            { suggestions?.map((suggestion: any, idx: number) => (
              <li key={ idx } className='cursor-pointer py-1' onClick={ (e) => onSelected(e) }>{ suggestion }</li>
            )) }
          </ul>
        </div> }

        <ErrorMessage
          name={ "AutoComplete" }
          component="div"
          className="text-red-500 text-base"
        />
      </div>
    </>
  )
}

export default AutoCompleteList