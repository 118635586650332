import { OperationVariables, QueryResult } from '@apollo/client';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { appTimeZone } from '../../globals';
import { Farm, Meter } from '../../utils/typedefs';


type Props = {
  currentlySelectedMeter: Meter | null;
  allMeters: QueryResult<any, OperationVariables>;
  getDeviceSamplesCallback: (meter: Meter) => void;
}

const MeterHeader: React.FC<Props> = ({ currentlySelectedMeter, allMeters, getDeviceSamplesCallback }) => {
  const [farmOpen, setFarmOpen] = useState(false);
  const [meterOpen, setMeterOpen] = useState(false);
  const [mobileSelectedFarmIndex, setMobileSelectedFarmIndex] = useState<number>(0);
  const [mobileSelectedMeterName, setMobileSelectedMeterName] = useState<String>();
  const [farmNames, setFarmNames] = useState<Array<string>>([""]);
  const [meterNames, setMeterNames] = useState<Array< { farmIndex: number, meter: Meter}>>();
  const [currentlySelectedMeterMenuItem, setCurrentlySelectedMeterMenuItem] = useState<number>(0)


  const handleMeterNameClick = (meter: Meter, menuItemMeterIndex: number) => {
    setCurrentlySelectedMeterMenuItem(menuItemMeterIndex);
    setMobileSelectedMeterName(meter?.name);
    getDeviceSamplesCallback(meter);
    setFarmOpen(false);
    setMeterOpen(false);
  }
  const handleFarmNameClick = (menuItemFarmIndex: number) => {
    setMobileSelectedFarmIndex(menuItemFarmIndex);
    setMobileSelectedMeterName("");
  }



  useEffect(() => {
    const farmNamesList = [...new Set<string>(allMeters?.data?.meters?.map((meter: Meter)=> meter.farm.name))];
    let meterFarmIdx: any = [];
    
    farmNamesList.map((farmName: string, idx: number)=> {
      return allMeters.data.meters.map((meter: Meter)=> {
        if(farmName === meter.farm.name){
          meterFarmIdx.push({
            farmIndex: idx,
            meter: meter
          })
        }
      })
      
    })
    
    setFarmNames(farmNamesList);
    setMeterNames(meterFarmIdx);
  }, [])



  useEffect(() => {
    setMobileSelectedMeterName(currentlySelectedMeter?.name);
  }, [currentlySelectedMeter])

  return (
    <>
      {/* <!-- Mobile up --> */}
      <div className="bg-accent lg:hidden">
        <div className="relative flex flex-row" x-data="{ farmOpen: false, meterOpen: false }">
          <button
            className="flex flex-row items-center p-3 pr-8 space-x-2 text-lg text-white bg-secondary "
            onClick={() => { setFarmOpen(!farmOpen); setMeterOpen(!farmOpen ? true : false) }}
          >

            {farmNames[mobileSelectedFarmIndex]}
            <svg className={farmOpen ? "w-8 h-8 transition" : "w-8 h-8 transition -rotate-90"} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
              <path d="m9.5 12.172-4.75-4.75.851-.851L9.5 10.49l3.899-3.9.851.852-4.75 4.73Z" fill="currentColor" /></svg>
          </button>

          {farmOpen && <ul className="absolute left-0 z-20 w-auto py-1 mx-2 min-w-[160px] overflow-hidden font-normal bg-white border rounded shadow top-full">
            {!allMeters.loading && farmNames?.map((farm: string, idx: number) => {
              return <li key={idx}><a onClick={() => handleFarmNameClick(idx)}
              style={{ background: idx === mobileSelectedFarmIndex ? "#97dbf1" : "" }}
                className="inline-flex w-full p-3 px-6 hover:bg-secondary/20 cursor-pointer"
              >
                {farm}
              </a>
              </li>
            })}
          </ul>}

          <button
            className="flex flex-row items-center p-3 space-x-2 text-lg text-primary "
            onClick={() => setMeterOpen(!meterOpen)}
          >
            {mobileSelectedMeterName}
            <svg className={meterOpen ? "w-8 h-8 transition" : "w-8 h-8 transition -rotate-90"} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
              <path d="m9.5 12.172-4.75-4.75.851-.851L9.5 10.49l3.899-3.9.851.852-4.75 4.73Z" fill="currentColor" /></svg>
          </button>
            {/* METERS LOOP */}
          {meterOpen && <ul className="absolute left-40 z-20 w-auto py-1 mx-2 min-w-[160px] overflow-hidden font-normal bg-white border rounded shadow top-full">
            {meterNames?.map(({farmIndex, meter}, idx: number) => {
            if(farmIndex === mobileSelectedFarmIndex)
              return <li key={idx}><a
                style={{ background: idx === currentlySelectedMeterMenuItem ? "#97dbf1" : "" }}
                onClick={() => handleMeterNameClick(meter, idx)}
                className="inline-flex w-full p-2 px-4 transition rounded-lg hover:bg-secondary/20 cursor-pointer">
                {meter.name}
              </a></li>
            })}
          </ul>}

        </div>
      </div>
      {/* <!-- Tablet up --> */}
      <div className="hidden w-full py-6 lg:flex md:py-12 bg-secondary">
        <div className="flex flex-col justify-between space-y-4 md:items-center fluid-container md:flex-row md:space-y-0">
          <div className="text-white">
            <div>{currentlySelectedMeter?.farm?.name}</div>
            <h2 className="text-2xl font-bold md:text-4xl">{currentlySelectedMeter?.name}</h2>
          </div>

          <div className="text-right text-white">
            <div className="flex flex-row font-bold align-middle md:text-xl md:justify-end">
              <img src="/assets/icon-meter.svg" className="w-6 h-6 mr-2" />
              <span>Live Meters</span>
            </div>
            {currentlySelectedMeter && <div className="flex flex-row items-center text-sm opacity-80">
              {moment.tz(currentlySelectedMeter?.lastWebhookReceivedAt, appTimeZone).isSame(new Date(), 'day') ? 
              <div className="w-3 h-3 mr-2 duration-1000 bg-green-500 rounded-full animate-pulse"></div> 
              :
              <div className="w-3 h-3 mr-2 bg-red-700 rounded-full"></div>}
              {currentlySelectedMeter && `Last update ${moment.tz(currentlySelectedMeter?.lastWebhookReceivedAt, appTimeZone).format('ddd DD MMM YYYY hh:mm a')}`}
            </div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default MeterHeader
