import { gql } from '@apollo/client'


// **** INVOICES RELATED QUERIES ****
export const GET_ALL_INVOICES = gql`
   query getAllInvoices($searchFor: String!)  {
    getAllInvoices(searchFor: $searchFor) {
      customer
    }
  }
`