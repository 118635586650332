import React from 'react'

type Props = {
  title: string;
  secondaryTextArray?: Array<string>;
}

const NormalHeader: React.FC<Props> = ({ title, secondaryTextArray }) => {
  return (
    <div className="flex w-full py-6 md:py-12 bg-secondary">
      <div className="flex flex-row justify-between items-center space-y-4 md:items-center fluid-container md:flex-row md:space-y-0">
        <div className="text-white">
          <h2 className="text-xl font-bold md:text-4xl">{title}</h2>
        </div>

        <div className="text-white">
        {secondaryTextArray && secondaryTextArray.map((text: string, idx: number) => (
          <div key={idx} className="text-white">
            <h2 className="text-md font-bold md:text-lg">{text}</h2>
          </div>
        ))}
      </div>

      </div>
    </div>
  )
}

export default NormalHeader