
import moment from 'moment-timezone';
import React, { useEffect,  useState } from 'react'
import { LineChart} from '../../../components/Charts'
import 'chartjs-adapter-moment';
import { appTimeZone } from '../../../globals';
import { defaultTitle, IChartDataSet, initialChartDataSet, initialDatasetOptions } from './chartCard.globals';

type Props = {
  realtimeMeterData: any;
  realtimeMeterError: any;
  isLoading: boolean;
  timestamps: Date[];
}


const RealtimeChartCard: React.FC<Props> = ({ realtimeMeterData, isLoading, timestamps, realtimeMeterError }) => {
  const [realtimeChartData, setRealtimeChartData] = useState<IChartDataSet>(initialChartDataSet);
  const LPERMIN_TO_M3PERH = 0.06;
  let realtimeChartPoints: [{ x: Date, y: number }];


  useEffect(() => {
    if (!realtimeMeterData?.realtimeSamples?.length) { return };

    setRealtimeChartData((prev: any) => {
      return {
        ...prev,
        datasets: [{
          ...prev.datasets[0],
          data: realtimeChartPoints
        }],
      }
    });

    
  }, [ realtimeMeterData])

  const getMaxY = (inputArray: [{ x: Date, y: number }]): number => {
    let y: any = -Infinity;
    for (let p of inputArray) {
      if (y < p.y) y = p.y;

    }
    return y;
  };


  const realtimeChartConfigOptions = () => {
    try {
      if (!realtimeMeterData?.realtimeSamples?.length) { return };

      realtimeChartPoints = realtimeMeterData?.realtimeSamples?.map((point: any) => ({
        x: moment.tz(point.timestamp, appTimeZone).format('ddd DD MMM YYYY HH:mm'),
        y: point.delta / point.timeDelta * LPERMIN_TO_M3PERH
      }));

      const realtimeMaxY = getMaxY(realtimeChartPoints);


      let realtimeDatasetOptions = Object.assign({}, initialDatasetOptions);

      return {
        ...realtimeDatasetOptions,
        scales: {
          y: {
            ...defaultTitle.y,
            min: 0,
            max: realtimeMaxY > 0 ? Math.ceil(realtimeMaxY / 0.5) * 0.5 : 0.10,
          },
          x: {
            ...defaultTitle.x,
            type: 'time',
            ticks:{ maxTicksLimit:30, major: {enabled: true} },
            min: moment().tz(appTimeZone).subtract(250, 'minutes').format('YYYY MM DD HH:mm'),
            max: moment.tz((moment().add(2, 'minutes'), appTimeZone)).format('YYYY MM DD HH:mm'),
          }
        },

      }
    } catch (error) {
      console.log(error)

    }

  }


  const formatMeterReadingText = (readingValue: string | number) => {
    if(!readingValue && isLoading){
      return <span className='text-green-800'>Loading...</span>
    }
    if(!readingValue && !isLoading){
      return <span className='text-black'>No data. Add a Meter Reading in settings</span>
    }
    
    return <span className='text-black'>{readingValue}</span> 
  }
  
  
  const realtimeConfigOptions = realtimeChartConfigOptions();



  return (
    <div className="py-12 space-y-8">
      {/* REALTIME CHART */}
      <section className="">
        <div className="flex flex-row items-center justify-between px-4 pb-2 md:px-0">
          <h2 className="text-xl font-bold text-primary">Real Time <span className='text-sm'>-  Meter Reading: {formatMeterReadingText(realtimeMeterData?.calculatedMeterReading)}</span></h2>
          <div className="text-sm opacity-50 text-primary">{moment(timestamps[timestamps.length - 1]).format('DD MMM')}</div>
        </div>
        <div className="w-full card">
          <ul className="flex flex-row items-end p-4 border-b text-primary border-primary/25">

            <li className="px-4 border-l first-of-type:border-0 border-primary/25 last-of-type:pl-4 last-of-type:ml-auto last-of-type:pr-0 first-of-type:pl-0">

              {isLoading && <span className="text-xl font-bold text-green-800">Loading Realtime chart data...</span> }
              {!realtimeMeterData?.realtimeSamples?.length && !realtimeMeterError && !isLoading && <span className="text-xl font-bold text-red-800">No samples found.</span> }
              {realtimeMeterError && <span className="text-xl font-bold text-red-800">{realtimeMeterError.message}</span>}  
              {realtimeMeterData?.realtimeSamples?.length && !isLoading && <>
                <span className="text-xl font-bold">{realtimeMeterData && realtimeMeterData?.realtimeTotalUsage?.toFixed(3)}<sup className="px-0.5 text-xs font-normal opacity-50">m3</sup></span>
                <span className="pl-0.5 tracking-tight opacity-80"> - last 4 hours</span>
              </>
              }
            </li>
            <li className="px-4 border-l first-of-type:border-0 border-primary/25 last-of-type:pl-4 last-of-type:ml-auto last-of-type:pr-0 first-of-type:pl-0">
            </li>

          </ul>
          <div className="relative w-full p-4 h-100">
            {realtimeMeterData?.realtimeSamples?.length >0 && <LineChart chartConfigOptions={realtimeConfigOptions} chartData={isLoading ? initialChartDataSet : realtimeChartData} />}
          </div>
        </div>
      </section>
    </div>
  )
}

export default RealtimeChartCard