import { useMutation } from '@apollo/client'
import { RENEW_JWT } from '../queriesAndMutations/mutations/user.mutations'
import {tokenName} from "../globals"


export const useNewToken = () => {
    const [renewJWT] = useMutation<any>(RENEW_JWT,{
        onCompleted: (data: any) => {
            return data.renewJWT
        },
        onError(error: any) {
          return error.message;
        }
      });
    
    const getToken = async (authToken: string) => {
        const res = await renewJWT({variables:{authToken}}); 
       return res.errors ? res.errors : res.data.renewJWT;
    };
     const setToken = (tokenData: string) => {
        try {
            localStorage.setItem(tokenName, tokenData);

        } catch (err) {
            console.log(err)
        }
    }
     const removeToken = () => {
        try {
            localStorage.removeItem(tokenName);

        } catch (err:any) {
            console.log(err)
        }
    }
    return { getToken, setToken, removeToken }
}

