// Interfaces

import moment from "moment"

export interface IDefaultTitle {
    y: {
      title: {
        display: boolean,
        text: string
        color: string
      },
    },
    x: {
      title: {
        display: boolean,
        text: string
        color: string
      },
    }
  }
  
  export interface IInitialDatasetOptions {
    maintainAspectRatio: boolean,
    responsive: boolean,
    clip: {
      left: boolean | number,
      top: boolean | number,
      right: boolean | number,
      bottom: boolean | number
    },
    animation: boolean,
    scales: {
      y: {
        title: {
          display: boolean,
          text: string,
          color: string,
        },
        min: number,
        max?: number,
      },
      x: {
        type: string,
        min: string,
        max: string,
      }
    },
    plugins: {
      legend: {
        display: boolean,
      },
      filler: {
        propagate: boolean
      },
      tooltip: {
        backgroundColor: string,
        titleFont: {
          size: number
        },
        bodyFont: {
          size: number
        },
        usePointStyle: boolean,
        callbacks: {
          labelColor: () => {
  
            borderColor: string,
            backgroundColor: string,
            borderWidth: number,
            borderDash: [number, number],
            borderRadius: number
  
          },
          label: (context: any) => {
  
          },
          title?: (context: any) => {
  
          },
  
        }
      }
    }
  }
  
  export interface IChartDataSet {
    datasets: [{
      data: [{ x: Date, y: Number }] | null
      label: String;
      borderColor: String;
      backgroundColor: String;
      fill: Boolean;
      pointStyle: string,
      pointRadius: number
      type?: string
    }]
  }


  //  Constants

  export const defaultTitle: IDefaultTitle = {
    y: {
      title: {
        display: true,
        text: "Water Usage - m3 / h ",
        color: "rgba(40,100,153,0.5)"
      },
    },
    x: {
      title: {
        display: true,
        text: "Time of day",
        color: "rgba(40,100,153,0.5)"
      },
    }
  }

  export const initialChartDataSet: IChartDataSet = {
    datasets: [{
      data: null,
      label: "Water Usage (m3)",
      borderColor: "#3e95cd",
      backgroundColor: "rgba(40,100,153,0.1)",
      fill: false,
      pointStyle: 'circle',
      pointRadius: 0.1,
      type: "line"
    }]
  }
  
  export const initialDatasetOptions: IInitialDatasetOptions = {
    maintainAspectRatio: true,
    responsive: true,
    clip: { left: 2, top: 5, right: 5, bottom: 5 },
    animation: true,
    scales: {
      y: {
        title: {
          display: true,
          text: "Water Usage (m3)",
          color: "rgba(40,100,153,0.5)"
        },
        min: 0,
      },
      x: {
        type: 'time',
        min: 'setMin',
        max: 'setMax',
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      filler: {
        propagate: true
      },
      tooltip: {
        backgroundColor: "rgba(40,100,153,0.9)",
        titleFont: {
          size: 20
        },
        bodyFont: {
          size: 20,
        },
        usePointStyle: true,
        callbacks: {
          labelColor: function () {
            return {
              borderColor: 'rgb(0, 0, 255)',
              backgroundColor: 'rgb(255, 0, 0)',
              borderWidth: 2,
              borderDash: [2, 2],
              borderRadius: 2,
            };
          },
          label: function (context: any) {
        
            if (context.dataset.type === "bar") {
              return `${context.parsed.y.toFixed(3).toLocaleString()} m3 Total`;
            }
           
              return `${context.parsed.y.toFixed(3).toLocaleString()} m3 / h`;
            
          },
          title: function (context: any) {
            if (context[0].dataset.type === "bar") {
              return (context[0].raw.x).toString();
            }
            return `${moment(context[0].label).format(" DD MMM")}, ${moment(context[0].label).format("HH:mm a")}`

            
          }
        }
      }
    }
  }

  export const sevenDailyChartOptions = {
    type: "line",
    legend: {
        display: true,
        position: 'top',
        labels: {
           boxWidth: 80,
           fontColor: 'black'
        }
      },
     tooltips:{
            mode: 'index',
            intersect: false,
            position: 'nearest'
     },
     maintainAspectRatio: true,
     responsive: true,
     clip: { left: 2, top: 5, right: 5, bottom: 5 },
     animation: true,
     
     scales: {
       y: {
        type: 'linear',
        display: true,
        position: 'left',
         title: {
           display: true,
           text: "Water Usage (m3)",
           color: "rgba(40,100,153,0.5)"
         },
         min: 0,
       },
     },
     plugins: {
       legend: {
         display: true,
         title: {
          display: true,
          text: 'Click on a colour to Show/Hide it.',
        },
       },
       filler: {
         propagate: true
       },
       tooltip: {
         backgroundColor: "rgba(40,100,153,0.9)",
         titleFont: {
           size: 20
         },
         bodyFont: {
           size: 20,
         },
         usePointStyle: true,
         callbacks: {
          
           labelColor: function(context: any) {
             return {
               borderColor: 'rgb(0, 0, 255)',
               backgroundColor : context.dataset.backgroundColor,
               borderDash: [2, 2],
               borderRadius: 2,
             };
           },
           label: function (context: any) {
              return `${context.parsed.y.toFixed(3).toLocaleString()} m3 / h`;
          },
           title: function (context: any) {
            
            return `${context[0].dataset.label}, ${moment(context[0].label).format("hh:mm a")}`;
          }
          
         }
       }
     }
    };
