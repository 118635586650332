
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react'
import { LineChart } from '../../../components/Charts'
import 'chartjs-adapter-moment';
import { IChartDataSet, initialChartDataSet, initialDatasetOptions } from './chartCard.globals';
import { appTimeZone } from '../../../globals';

type Props = {
  twoAmMeterData: any;
  twoAmMeterError: any;
  isLoading: boolean;
  timestamps: Date[];
}



const TwoAMChartCard: React.FC<Props> = ({ isLoading, timestamps, twoAmMeterData, twoAmMeterError }) => {

  const [twoAmChartData, setTwoAmChartData] = useState<IChartDataSet>(initialChartDataSet);
  const twoAmPointsError = useRef<{ error: boolean, errorMessage: string }>({ error: false, errorMessage: "" });
  let oneToTwoAmChartPoints: any;


  useEffect(() => {
    if (!twoAmMeterData) { return };


    setTwoAmChartData((prev) => {
      return {
        ...prev,
        labels: twoAmMeterData?.twoAMSampleChartData?.map((data: any) => moment(data.isoDate).tz(appTimeZone).format("ddd DD")),
        datasets: [{
          ...prev.datasets[0],
          data: oneToTwoAmChartPoints,
          backgroundColor: "rgb(151, 219, 241)",
          type: "bar"
        }],
      }
    });

  }, [twoAmMeterData])



  const getMaxY = (inputArray: [{ x: Date, y: number }]): number => {
    let y: any = -Infinity;
    for (let p of inputArray) {
      if (y < p.y) y = p.y;

    }
    return y;
  };

  

  const twoAmChartConfigOptions = () => {
    try {
      twoAmPointsError.current.error = false;
      twoAmPointsError.current.errorMessage = "";

      if (!twoAmMeterData?.twoAMSampleChartData?.length) { return };

      oneToTwoAmChartPoints = twoAmMeterData?.twoAMSampleChartData?.map((point: any) => ({
        x: moment(point.isoDate).tz(appTimeZone).format("ddd DD MMM"),
        y: point.totalUsage
      }));

      if (!oneToTwoAmChartPoints.length) { twoAmPointsError.current.error = true; twoAmPointsError.current.errorMessage = "No 2am device readings found"; return; }


      const twoAmMaxY = getMaxY(oneToTwoAmChartPoints);


      let oneToTwoAmDatasetOptions = Object.assign({}, initialDatasetOptions);

      return {
        ...oneToTwoAmDatasetOptions,
        scales: {
          y: {
            title: {
              display: true,
              text: "m3 Total",
              color: "rgba(40,100,153,0.5)"
            },
            min: 0,
            max: twoAmMaxY > 0 ? Math.ceil(twoAmMaxY / 5) * 5 : 0.10,
            ticks: {
              // forces step size to be 50 units
              stepSize: 50
            }
          },
          x: {
            title: {
              display: true,
              text: "Day of current month",
              color: "rgba(40,100,153,0.5)"
            },
            min: oneToTwoAmChartPoints[0].x,
          }
        },

      }
    } catch (error) {
      console.log(error)

    }

  }

  const increaseOrDecrease = (value: number) => {
    if (value > 0) { return "Increase" }
    if (value === 0) { return "Same" }

    return "Decrease";
  }



  const twoAmConfigOptions = twoAmChartConfigOptions();


  
  return (
    <div className="py-12 space-y-8">
      {/* 2AM CHART */}
      <section className="">
        <div className="flex flex-row items-center justify-between px-4 pb-2 md:px-0">
          <h2 className="text-xl font-bold text-primary">1am - 2am</h2>
          <div className="text-sm opacity-50 text-primary">{moment(timestamps[timestamps.length - 1]).format('DD MMM')}</div>
        </div>
        <div className="w-full card">
          <ul className="flex flex-row items-end p-4 border-b text-primary border-primary/25">

              {!twoAmMeterData?.twoAMSampleChartData?.length && isLoading && <span className="text-xl font-bold text-green-800">Loading 2am chart data...</span> }

              {!twoAmMeterData?.twoAMSampleChartData?.length && !twoAmMeterError && !isLoading && <span className="text-xl font-bold text-red-800">No samples found.</span> }

              {twoAmMeterError && <span className="text-xl font-bold text-red-800">{twoAmMeterError.message}</span>}  

              {twoAmMeterData?.twoAMSampleChartData?.length && !isLoading && <>
              <li className="px-4 border-l first-of-type:border-0 border-primary/25 last-of-type:pl-4 last-of-type:ml-auto last-of-type:pr-0 first-of-type:pl-0">
                <span className="text-xl font-bold">{twoAmMeterData && (parseFloat(twoAmMeterData?.twoAMSampleChartData[twoAmMeterData?.twoAMSampleChartData?.length -1].totalUsage).toFixed(3).toLocaleString())}<sup className="px-0.5 text-xs font-normal opacity-50">m3</sup></span>
                <span className="pl-0.5 tracking-tight opacity-80">Today</span>
              </li>
              <li className="px-4 border-l first-of-type:border-0 border-primary/25 last-of-type:pl-4 last-of-type:ml-auto last-of-type:pr-0 first-of-type:pl-0">
                <span className="text-xl font-bold">{twoAmMeterData && twoAmMeterData?.twoAMSampleChartData?.length <2 ? "--" : (parseFloat(twoAmMeterData?.twoAMSampleChartData[twoAmMeterData?.twoAMSampleChartData?.length -2].totalUsage).toFixed(3).toLocaleString())}<sup className="px-0.5 text-xs font-normal opacity-50">m3</sup></span>
                <span className="pl-0.5 tracking-tight opacity-80"> Yesterday</span>
              </li>
              <li className="px-4 border-l first-of-type:border-0 border-primary/25 last-of-type:pl-4 last-of-type:ml-auto last-of-type:pr-0 first-of-type:pl-0">
                <span className="text-xl font-bold">{twoAmMeterData && parseFloat(twoAmMeterData?.twoAMdiffTodayYesterday).toFixed(3).toLocaleString()}<sup className="px-0.5 text-xs font-normal opacity-50">m3</sup></span>
                <span className="pl-0.5 tracking-tight opacity-80">{increaseOrDecrease(twoAmMeterData?.twoAMdiffTodayYesterday)}</span>
              </li>
            </>
            }

          </ul>
          <div className="relative w-full p-4 h-100">
            {twoAmPointsError.current.error && twoAmPointsError.current.errorMessage}
            {!twoAmPointsError.current.error && twoAmMeterData && <LineChart chartConfigOptions={twoAmConfigOptions} chartData={isLoading ? initialChartDataSet : twoAmChartData} />}
          </div>
        </div>
      </section>

    </div>
  )
}

export default TwoAMChartCard