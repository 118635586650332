import React from 'react'
import NormalHeader from '../../components/headers/NormalHeader'
import SettingsAccount from './SettingsAccount'
import SettingsFarms from './SettingsFarms'
import SettingsMeters from './SettingsMeters'

type Props = {}

const Settings: React.FC<Props> = () => {
  return (
    <div className="flex flex-col min-h-screen site-wrapper">
      <header>
        <NormalHeader title={'Settings'} />
      </header>

      <main>
        
        <SettingsAccount />

        <div className="hidden md:fluid-container md:block">
          <hr className="border-primary/20" />
        </div>
        <SettingsMeters />

        <div className="hidden md:fluid-container md:block">
          <hr className="border-primary/20" />
        </div>
        <SettingsFarms />
      </main>
    </div>
  )
}

export default Settings
