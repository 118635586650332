import { ErrorMessage, Field } from 'formik'

type Props = {
  name: string;
  label: string;
  isPassword?: boolean;
  unit?: string;
  onLoadValue?: string;
  isLoading: boolean;
  fullWidth? :boolean
}

const FormikInput: React.FC<Props> = ({ name, label, unit, onLoadValue, isPassword, isLoading, fullWidth, ...rest }) => {
  return (
    <div className={`form-control inline-block min108 ${fullWidth && "w-full"}`}>
         <label className="flex flex-col grow ">
          <span className="pb-1 text-gray-700 font-bold ">{label}</span>
          <div className="relative">
            <Field
              className="block w-full pr-8 transition border-2 border-gray-300 rounded-md shadow-sm hover:bg-secondary/10 focus:border-secondary focus:ring focus:ring-secondary/50 focus:ring-opacity-50 bg-secondary/5"
              style={{height: "47px"}}
              as={"input"}
              id={name}
              name={name}
              value={onLoadValue}
              disabled={isLoading}
              type={isPassword ? "password" : ""}
              {...rest}
            />
            {unit && (
              <div className="absolute top-0 bottom-0 right-0 flex items-center h-auto px-3 align-middle border-l-2 bg-secondary/10 border-secondary/10 ">
                {unit}
              </div>
            )}
          </div>
        </label>
      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-base"
      />
    </div>
  )
}

export default FormikInput