import jwt_decode from "jwt-decode";

interface IToken {
    exp: number;
}
    export const decodeTokenExpiry  = async (token: string): Promise<number|null> => {
        return new Promise(resolve => {
                
      if(token && token.length > 50){
            const decodedToken: IToken = jwt_decode(token);
            resolve(decodedToken.exp)
      }
      resolve(null)
        })

    }
   

