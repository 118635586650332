import { gql } from '@apollo/client'

// **** FARM RELATED MUTATIONS ****

export const ADD_NEW_FARM = gql`
  mutation addNewFarm(
    $name: String!
    $size: Float!
  ) {
    farmCreate( input: {name: $name size: $size }) {
       _id
    	name
    	meters {_id name}
      size
  }
  }
`

export const UPDATE_FARM = gql`
  mutation updateFarm(
    $_id: String!
    $name: String!
    $size: Float!
    
  ) {
    farmUpdate( input: { _id: $_id name: $name size: $size}) {
      _id
      	name
      	meters {_id name}
        size
      }
  }
`
export const DELETE_FARM = gql`
  mutation deleteFarm($_id: String!) {
    farmDelete(_id: $_id)
  }
`