import React from 'react'
import { Link, Path, To } from 'react-router-dom';

type Props = {
  notifyType: string; //should be enum
  notifyTextStrong: string;
  notifyTextBeforeLink: string;
  notifyTextAfterLink?: string;
  notifyLink?: any; //TODO: Should be a type Too but it errors... Set to type any for now
  notifyLinkText?: string;
}

const Notification: React.FC<Props> = ({ notifyTextBeforeLink, notifyTextAfterLink, notifyTextStrong, notifyLink, notifyLinkText, notifyType }) => {

  return (
    <aside className="flex flex-row w-full mb-2 card">
      <div className="flex items-center p-2 px-4 border-r-2 bg-red-500/10 border-red-300/20">
        <img src={notifyType == "ALERT" ? "/assets/icon-alert.svg" : "/assets/icon-error.svg"} className="w-8 h-8" />
      </div>
      <div className="flex items-center p-4 prose max-w-full prose-a:hover:bg-primary/10 prose-a:rounded">
        <span className='w-full'>
          <b>{notifyTextStrong}</b>
          {' '}
          {notifyTextBeforeLink} 
          {' '}
          <Link to={notifyLink}>{notifyLinkText}{' '}</Link>
          {' '}
          {notifyTextAfterLink}
        </span>
      </div>
    </aside>
  )
}

export default Notification
