import React from "react";
import { Bar, Line } from "react-chartjs-2";
import { Chart, ChartOptions, registerables } from 'chart.js';
Chart.register(...registerables);

type Props = {
  chartData: any
  chartConfigOptions: any
}


const LineChart = ({ chartData, chartConfigOptions }: Props) => {
  return <Line data={chartData}  options={chartConfigOptions}/>;
}

const BarChart = ({ chartData, chartConfigOptions }: Props) => {
  return <Bar data={chartData}  options={chartConfigOptions}/>;
}

export  {BarChart, LineChart};




