import { useContext } from 'react'
import HeaderNav from '../../components/navigation/HeaderNav'
import MobileNav from '../../components/navigation/MobileNav'
import { AppStateContext, InitialAppState } from '../../context/appStateContext';


type SiteWrapperProps = {
  children: React.ReactNode
}

const SiteWrapper: React.FC<SiteWrapperProps> = ({ children }) => {
  const { appState} = useContext<Partial<InitialAppState>>(AppStateContext);

  const toggleMenuOpenClose = () => {
    document.querySelector('.mobile-nav')?.classList.toggle('open')
  }
  return (
    <>
     {appState.userId && <header>
        <HeaderNav toggleMenuOpenClose={toggleMenuOpenClose} />
        <MobileNav toggleMenuOpenClose={toggleMenuOpenClose} />
      </header>}
      <main>{children}</main>
    </>
  )
}

export default SiteWrapper
