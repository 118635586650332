import { useQuery } from '@apollo/client'
import moment from 'moment';
import { useEffect } from 'react';
import { appTimeZone } from '../../globals';
import { GET_RAW_SAMPLES } from '../../queriesAndMutations/queries/samples.queries';
import { RawSample } from '../../utils/typedefs';

const RawSamples = () => {
  const pollingTime = 10000;

  const allSamples = useQuery<any>(GET_RAW_SAMPLES, { fetchPolicy: "network-only", pollInterval: pollingTime });

  useEffect(()=>{
    //currently polling aler history every 10 seconds
    allSamples.startPolling(pollingTime);
     },[])
  
  return (
    <>
        <h3 className="text-md font-bold text-primary my-6 italic">This page displays the last 20 Samples received from all devices.</h3>
      <div className="flex flex-row items-center justify-between px-4 pb-2 md:px-0">
        <h2 className="text-xl font-bold text-primary my-6">Latest Samples:</h2>
      </div>
      <div className="card">
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-flex min-w-full">
              <div className="w-full overflow-hidden">
              {allSamples.loading && <h1 className='p-4'>Fetching Latest Samples...</h1>}
              {allSamples.error && <h1 className='p-4'>Error Fetching Latest Samples.</h1>}

                {!allSamples.loading && allSamples?.data?.getRawSamples?.map((sample: RawSample, idx: number) => (
                  <ul key={idx} className="">
                    <li className='py-8 px-4' style={idx%2 ? {}: {backgroundColor: '#ffe0de'}}>
                      <b>
                      <p className='p-2'> {moment(sample?.timestamp).format("DD/MM/YYYY H:mm")}</p>
                         <br />
                      <p className='p-2'> Counter: {sample?.value}</p>
                      <p className='p-2'> DeviceEUI: {sample?.deviceEUI}</p> <br />
                      <p className='p-2'> Value: {sample?.value}</p> <br />
                      <p className='p-2'> Delta: {sample?.delta}</p> <br />
                      <p className='p-2'> Webhook Message:</p> <br />
                      </b>
                        <span className='p2'><b>received_at:</b> {moment.tz(sample?.webhook_message.received_at, appTimeZone).format("DD/MM/YYYY HH:mm:ss")}</span>  <br />
                      <pre className='p-4'>
                        {/* {JSON.stringify(JSON.parse(sample?.webhookMessage), null, 4)} */}
                        <ul> 
                       <span className='p2'><b>Correlation_ids:</b></span> {sample?.webhook_message.correlation_ids.map((corrId: any, corrIdx: number)=> {
                          return <li key={corrIdx}>{corrId}</li>})}
                        </ul>
                      </pre>
                    </li>
                  </ul>

                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RawSamples