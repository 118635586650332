

const mockAlertSpecEnum = [
    {
      _id: "ALL_DAY",
      name: "Daily"
    },
    {
      _id: "AM2",
      name: "1am-2am"
    }
  ]
   

export const useGetAlertSpec = () => {

    const alertSpecToUsableString = (alertSpecEnum: string): String => {
        //convert alertSpec into usable string for dropdowns etc.
        try {
            if (alertSpecEnum.includes("ALL_DAY")) {
                return "Daily";
            }
            return "1am-2am";

        } catch {
            return "An error occurred";

        }
    };
    const getAlertSpecTypes = (): typeof mockAlertSpecEnum => {
        return mockAlertSpecEnum;
    };

    return { getAlertSpecTypes, alertSpecToUsableString }
}

