
export const tokenName = "ccAccessToken";

export interface IUser {
    _id: string,
    name: string,
    email: string,
    token: string
  }


  export interface IMessage {
    text: string;
    colour: string;
  }

  export const messageTimerDisplayTime = 2000;
  
  export const appTimeZone = "Pacific/Auckland";
  