import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_ALL_ALERTS } from '../../queriesAndMutations/queries/alert.queries'
import Modal from '../../components/modals/Modal'
import AlertCreateInput from '../../components/graphQLInputs/createInputs/AlertCreateInput'
import { useGetAlertSpec } from '../../hooks/useGetAlertSpec'
import ModalDeleteConfirmation from '../../components/modals/ModalDeleteConfirmation'
import { IMessage, messageTimerDisplayTime } from '../../globals'

type Props = {

  setUpdateResponse: React.Dispatch<React.SetStateAction<IMessage>>;
  updateResponse: IMessage;
}

const AlertSetup: React.FC<Props> = ({setUpdateResponse, updateResponse}) => {
  //state
  const [alertId, setAlertId] = useState<string>("");
  const [meterId, setMeterId] = useState<string>("");
  const [alertMeterNameForDelete, setAlertMeterNameForDelete] = useState<string>("");
  const [alertSpecType, setAlertSpecType] = useState<string>("");
  const [alertVolume, setAlertVolume] = useState<string>("");
  const [farmName, setFarmName] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { alertSpecToUsableString } = useGetAlertSpec();


  //GraphQL
  const allAlerts = useQuery<any>(GET_ALL_ALERTS);

  const handleEdit = (alertId: string, meterId: string, alertSpec: string, volume: string, farmName: string) => {
    setAlertId(alertId);
    setMeterId(meterId);
    setAlertSpecType(alertSpec);
    setAlertVolume(volume);
    setFarmName(farmName);
    //Show edit modal
    setShowModal(true);
  }

  const handleDelete = (id: string, alertMeterName: string, alertSpec: string, volume: string) => {
    setAlertId(id);
    setAlertSpecType(alertSpec);
    setAlertVolume(volume);
    setAlertMeterNameForDelete(alertMeterName);
    setShowDeleteModal(true);
  }


  const startMessageTimer = () => {
    setTimeout(() => {
      setUpdateResponse && setUpdateResponse({ text: "", colour: "" });
    }, messageTimerDisplayTime);
  };

  useEffect(() => {
    if(updateResponse.text){
      if( !updateResponse.text.includes("Please wait...")){
        startMessageTimer();
      }
    }
  }, [updateResponse])

  return (
    <section>
      <div className="flex flex-row items-center justify-between px-4 pb-2 md:px-0">
        <h2 className="text-xl font-bold text-primary">Alert Setup</h2>
      </div>
      <div className="card">
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-flex min-w-full">
              <div className="w-full overflow-hidden">
                <table className="min-w-full alert-setup-table">
                  <thead className="border-b-2 border-secondary/2">
                    <tr>
                      <th scope="col">Farm</th>
                      <th scope="col">Meter</th>
                      <th scope="col">Alert Trigger</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {allAlerts.loading && (
                      <tr>
                        <td>Loading Data...</td>
                      </tr>
                    )}
                    {!allAlerts.loading && !allAlerts.error && !allAlerts?.data?.alerts?.length && (
                      <tr>
                        <td>No Alerts to display. Add a new Alert below...</td>
                      </tr>
                    )}
                    {allAlerts.error && (
                      <tr>
                        <td>{allAlerts?.error?.message}</td>
                      </tr>
                    )}
                    {!allAlerts.loading && !allAlerts.error && allAlerts?.data?.alerts?.map((alert: any) => {
                      return <tr key={alert?._id} className="odd:bg-secondary/5 hover:bg-secondary/10">
                        <td>{alert?.farm?.name}</td>
                        <td>{alert?.meter?.name}</td>
                        <td>
                          {` Usage ${alertSpecToUsableString(alert.alertSpec)} exceeded`} <span><b>{alert.volume} m3</b></span>
                        </td>
                        <td className="px-6 py-1 text-sm font-light text-right text-gray-900 whitespace-nowrap">
                          <button onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleEdit(alert?._id, alert?.meter?._id, alert?.alertSpec, alert?.volume, alert?.farm?.name)} className="button-table mr-2">Edit</button>
                          <button onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleDelete(alert?._id, alert?.meter?.name, alert?.alertSpec, alert?.volume)} className="button-table bg-red-300 hover:bg-red-400 text-blue-900">
                            Delete
                          </button>
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
                {updateResponse.text && <div className={`w-full text-lg my-4 bg-slate-200 flex flex-col items-center align-center`}>
                          <h1 style={{ color: updateResponse.colour }} className='p-2'>{updateResponse.text}</h1>
                        </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDeleteModal &&
        <Modal isOpen={showDeleteModal} onClose={setShowDeleteModal}>
          <ModalDeleteConfirmation toDeleteName={alertMeterNameForDelete} _id={alertId} alertSpec={alertSpecType} alertVolume={parseFloat(alertVolume)} deleteAlert onClose={setShowDeleteModal} setUpdateResponse={setUpdateResponse} />
        </Modal>}
      {showModal &&
        <Modal isOpen={showModal} onClose={setShowModal}>
          <AlertCreateInput formId='edit-alert' alertSpecType={alertSpecType} meterId={meterId}  farmName={farmName} alertToEditId={alertId} editMode={true} volume={alertVolume} setUpdateResponse={setUpdateResponse} />
        </Modal>}
    </section>

  )
}

export default AlertSetup

