import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import * as Yup from 'yup'
import { Form, Formik, FormikProps } from 'formik'

// Import GraphQL queries and mutations
import { ADD_NEW_METER } from '../../../queriesAndMutations/mutations/meter.mutations'
import { UPDATE_METER } from '../../../queriesAndMutations/mutations/meter.mutations'
import { GET_ALL_METERS } from '../../../queriesAndMutations/queries/meter.queries'
import { GET_ALL_FARMS } from '../../../queriesAndMutations/queries/farm.queries'
import FormikSelect from '../../fields/FormikSelect'
import FormikInput from '../../fields/FormikInput'
import { IMessage } from '../../../globals'



type Props = {
  meterToEditId?: string;
  editMode: boolean;
  formId: string;
  farmId?: string;
  farmName?: string;
  meterName?: string;
  meterCode?: string;
  handleClose?: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateResponse: React.Dispatch<React.SetStateAction<IMessage>>;

}


type InitialValues = {
  _id: string;
  farmId: string;
  farmName: string;
  meterName: string;
  meterCode: string;
}

const initialValues = {
  _id: '',
  farmId: '',
  farmName:  '',
  meterName: '',
  meterCode: ''
}

const MeterCreateInput: React.FC<Props> = ({ meterToEditId = '', editMode, formId, farmId = '', farmName = '',  meterCode = '', meterName = '', handleClose, setUpdateResponse}) => {

  const [addNewMeter, { called: addMeterCalled, loading: addMeterLoading, reset: meterMutationReset }] = useMutation<any>(ADD_NEW_METER);
  const [updateMeter] = useMutation<any>(UPDATE_METER);
  const allMeters = useQuery<any>(GET_ALL_METERS);
  const allFarms = useQuery<any>(GET_ALL_FARMS);

  //state to handle edited values
  const [valuesToEdit, setValuesToEdit] = useState<InitialValues>(initialValues);

  const validationSchema = Yup.object({
    farmId: Yup.string().required('Farm Required'),
    meterName: Yup.string().required('Meter Required'),
    meterCode: Yup.string().required('MeterCode Required')
  });

  const onSubmit = (values: any) => {

    if (editMode) {
      if (values.meterCode !== meterCode || values.meterName !== meterName) {   //If values have't changed on edit - don't mutate via API
        setUpdateResponse && setUpdateResponse({ text: "Updating Meter. Please wait...", colour: "#2b679b" });
        updateMeter({
          variables: {
            _id: values._id,
            farmId: values.farmId,
            name: values.meterName,
            meterCode: values.meterCode
          },
          update(cache, { data }) {

            if (!data.meterUpdate) { return };
  
            const metersQuery: any = cache.readQuery({ query: GET_ALL_METERS });

            const nonEditedMeters = metersQuery.meters.filter((meter:any)=> meter._id !== data.meterUpdate._id && meter._id !== meterToEditId);

            cache.writeQuery({
              query: GET_ALL_METERS,
              data: { meters: [...nonEditedMeters, data.meterUpdate] }
            });
  
          }
        }).then((response: any) => {
          if (response.data) {
            setUpdateResponse && setUpdateResponse({ text: "Meter updated successfully!", colour: "green" });
            
          }
        })
          .catch((err: any) => {
            setUpdateResponse && setUpdateResponse({ text: err.message, colour: "red" });
            
          });;
      }
    } else {
      
      setUpdateResponse && setUpdateResponse({ text: "Adding New Meter. Please wait...", colour: "#2b679b" });

      addNewMeter({
        variables: {
          farmId: values.farmId,
          name: values.meterName,
          meterCode: values.meterCode
        },

        update(cache, { data }) {

          if (!data.meterCreate) { return };

          const metersQuery: any = cache.readQuery({ query: GET_ALL_METERS });
          cache.writeQuery({
            query: GET_ALL_METERS,
            data: { meters: [...metersQuery.meters, data.meterCreate] }
          });

        }
      }).then((response: any) => {
        if (response.data) {
          setUpdateResponse && setUpdateResponse({ text: "Meter added successfully!", colour: "green" });
          
        }
      })
        .catch((err: any) => {
          setUpdateResponse && setUpdateResponse({ text: err.message, colour: "red" });
          
        });;
    }

    //Close modal from passed down setState prop
    handleClose && handleClose(false);
  }

  useEffect(() => {
    if (!addMeterLoading && addMeterCalled) {
      setValuesToEdit(initialValues);
      meterMutationReset();
    }
  }, [addMeterCalled, addMeterLoading])

  const handleFormChange = (e: any) => {
    setValuesToEdit((prev) => {
      return { ...prev, [e.id]: e.value }
    })
  }


  useEffect(() => {
    editMode && setValuesToEdit({
      _id: meterToEditId,
      farmId: farmId,
      farmName: farmName,
      meterName: meterName,
      meterCode: meterCode
    })
  }, [meterCode])


  return (
    <Formik
      initialValues={valuesToEdit}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {(formik: FormikProps<any>) => {
        return allMeters.loading ? null : (
          <>
            <div className="w-full my-12 card divide-primary/10">
              <div className="p-4 px-6 card-topper ">
                <h3 className="text-xl font-bold text-primary">
                  {editMode ? 'Edit Meter' : 'Add New Meter'}
                </h3>
              </div>
              {!allFarms.loading && !allFarms?.data?.farms?.length ?
                (<table className="min-w-full">
                  <tbody>
                    <tr>
                      <td>You can't add a Meter without a Farm. Add a new Farm below to begin.</td>
                    </tr>
                  </tbody>
                </table>)
                : (
                  <Form id={formId} onChange={(e) => handleFormChange(e.target)}>
                    <div className="card-content">
                      <div className="flex flex-col p-4 px-6 space-y-4 md:space-x-8 md:flex-row md:space-y-0">
                        
                        {!editMode && <FormikSelect
                          label="Select a Farm"
                          name="farmId"
                          meterFarmCombine={false}
                          onLoadDropdownValue={
                            valuesToEdit.farmId
                          }
                          dropDownOptions={
                            allMeters?.loading
                              ? null
                              : allFarms?.data?.farms

                          }
                        />}
                        <FormikInput
                          label="Meter Name"
                          name="meterName"
                          onLoadValue={valuesToEdit.meterName}
                          isLoading={allMeters?.loading || allMeters?.error ? true : false}
                        />

                        {editMode && <label className='font-bold'>Farm Name
                          <div className='flex flex-col font-normal justify-center' style={{ height: "52px" }}><h2>{valuesToEdit.farmName}</h2></div>
                        </label>}

                        <FormikInput
                          label="MeterCode"
                          name="meterCode"
                          onLoadValue={valuesToEdit.meterCode}
                          isLoading={allMeters?.loading || allMeters?.error ? true : false}
                        />
                          <div className="flex flex-column items-end">
                          <button
                            form={formId}
                            className="button-base mt-4 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray "
                            type="submit"
                            disabled={!formik.isValid || [formik.values.farmId].includes("Select one...")} //Select one comes from FormikSelect component
                          >
                            {editMode ? 'Save Meter' : 'Go'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )
              }
            </div>
          </>
        )
      }}
    </Formik>
  )
}

export default MeterCreateInput
