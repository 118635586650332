import { gql } from '@apollo/client'

// **** USER RELATED MUTATIONS ****

export const UPDATE_USER = gql`
  mutation updateUser(
    $_id: String!
    $name: String!
    $email: String!
    $passwordCurrent: String!
    $password: String!
    $passwordAgain: String!
  ) {
    userUpdate( input: {_id: $_id, name: $name, email: $email passwordCurrent: $passwordCurrent, password: $password passwordAgain: $passwordAgain}) {
      _id
    	name
      email
    }
  }
`

export const USER_SIGN_UP = gql`
  mutation userSignup(
    $name: String!, 
    $email: String!, 
    $password: String!, 
    $passwordAgain: String!
    ) {
    userSignup(input: { name: $name, email: $email, password: $password, passwordAgain: $passwordAgain }) 
  }
`;

export const USER_LOGIN = gql`
  mutation userLogin( $email: String!, $password: String!) {
    userLogin(input: { email: $email, password: $password }) 
  }
`;


export const USER_SIGN_UP_TOKEN_VERIFY = gql`
mutation userTokenVerify($verificationToken: String!){
  userVerify(verificationToken: $verificationToken) {
      _id
      name
      email
      authToken
      tempResetPassword
  }
}`;

export const PASSWORD_RESET = gql`
mutation userResetLogin($email: String!){
  userResetLogin(email: $email)
}
`;

export const RENEW_JWT = gql`
 mutation renewJWT($authToken: String!){
  renewJWT(authToken: $authToken) 
}
`

