
import { Routes, Route, useNavigate, NavigateFunction } from 'react-router-dom'
import Alerts from '../pages/alerts/Alerts'
import LiveMeters from '../pages/liveMeters/LiveMeters'
import Login from '../pages/login/Login'
import Settings from '../pages/settings/Settings'
import Signup from '../pages/signup/Signup'
import SignupConfirmation from '../pages/signup/SignupConfirmation'
import SignupOrResetTokenVerify from '../pages/verifyToken/SignupOrResetTokenVerify'
import RequireAuth from '../utils/RequireAuth'
import { decodeTokenExpiry } from '../utils/decodeToken'
import './App.css'
import { useNewToken } from '../hooks/useNewToken'
import { tokenName } from "../globals"
import { useContext, useEffect, useRef } from 'react'
import ResetPassword from '../pages/passwordReset/ResetPassword'
import { AppStateContext } from '../context/appStateContext'
import AdminDashboard from '../pages/admin/AdminDashboard'


const worker = new Worker(new URL('../utils/jwt.js', import.meta.url));
let localToken: any;
let newToken: any;
let navigate: NavigateFunction;
let _logout: any;

const renewToken = async() => {

  try {
    
      // console.log("Renewing JWT...")
      const newJWT = await newToken.getToken(localToken.current);

      if(newJWT==="NOTFOUND" || newJWT?.message) { 
        throw Error(newJWT.message ? newJWT?.message : newJWT)
      }
      // console.log("newJWT", newJWT)
      newToken.setToken(newJWT)
      // console.log("JWT renewed")
    }
    catch (e) {
      // console.error("Failed to renew JWT: ", e)
      console.log({
        title: 'Error',
        message: 'Failed to renew your session. Please login again'
      })
       //remove token and redirect to login page...
       _logout && _logout();
       navigate("/")
    }
    finally {
    }
  }

const dispatchToWorker =(cmd: string, data?: any) =>{
  worker.postMessage({ what: cmd, data })
}

worker.addEventListener('message', async e => {
  switch (e.data.what) {
    case 'getExpiry':
      dispatchToWorker('getExpiry_response', await decodeTokenExpiry(localToken.current))
      break
    case 'renewToken':
      await renewToken()
      break;
  }
}, false);

dispatchToWorker('start')


function App() {
  const { appState, logout } = useContext(AppStateContext);
  _logout = logout;
  newToken = useNewToken();
  navigate = useNavigate();
  localToken = useRef<string>();

  useEffect(() => {
    localToken.current = localStorage.getItem(tokenName)
  }, [newToken, appState.userId])

  return (
    <Routes>
      <Route path="/" element={<RequireAuth><LiveMeters /></RequireAuth>} />
      <Route path="/login" element={<RequireAuth><Login /></RequireAuth>} />
      <Route path="/signup" element={<RequireAuth><Signup /></RequireAuth>} />
      <Route path="/alerts" element={<RequireAuth><Alerts /></RequireAuth>} />
      <Route path="/settings" element={<RequireAuth><Settings /></RequireAuth>} />
      <Route path="/admin" element={<RequireAuth><AdminDashboard /></RequireAuth>} />
      <Route path="/confirmation" element={<SignupConfirmation />} />
      <Route path="/user-verify" element={<SignupOrResetTokenVerify />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      {/* Catchall */}
      <Route path="/*" element={<RequireAuth><Login /></RequireAuth>} />
    </Routes>
  )
}

export default App
