import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './containers/App'
import SiteWrapper from './containers/layout/SiteWrapper'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client';
import client from './utils/apolloClient'
import { AppStateProvider } from './context/appStateContext'


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ApolloProvider client={client}>
    <AppStateProvider> 
      <BrowserRouter>
        <SiteWrapper>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </SiteWrapper>
      </BrowserRouter>
      </AppStateProvider>
  </ApolloProvider>
)