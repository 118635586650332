import { gql } from '@apollo/client'

// **** SAMPLES RELATED QUERIES ****



export const GET_SEVEN_DAILY_SAMPLES = gql`
  query getSevenDailyMeterSamples($deviceEUI: String!) {
    getSevenDailyMeterSamples(deviceEUI: $deviceEUI) {
     meterData {
               samples { _id
              delta
              timeDelta
              timestamp}
              }
              
        usage24hours
        usagePrevious24hours
        dailyUsageDifference
    }
  }
`
export const GET_DAILY_SAMPLES = gql`
   query getDailyMeterSamples($input: SampleInput!) {
    getDailyMeterSamples(input: $input) {
        meterData {
              _id
              delta
              timeDelta
              timestamp
              }
        usage24hours
        usagePrevious24hours
        dailyUsageDifference
    }
  }
`

export const GET_TWO_AM_TOTALS = gql`
   query getTwoAMTotals($deviceEUI: String!) {
    getTwoAMTotals(deviceEUI: $deviceEUI) {
      twoAMSampleChartData {
                    dayOfMonth
                    totalUsage
                    isoDate
                }
            twoAMdiffTodayYesterday
    }
  }
`

export const GET_REALTIME_SAMPLES = gql`
    query getRealtimeMeterSamples($deviceEUI: String!) {
    getRealtimeMeterSamples(deviceEUI: $deviceEUI) {
      realtimeSamples { _id
        delta
        timeDelta
        timestamp}
        realtimeTotalUsage
        calculatedMeterReading
    }
  }
`

export const GET_RAW_SAMPLES = gql`
    query getRawSamples {
    getRawSamples {
        _id
  webhook_message { 
      correlation_ids
      received_at
      
      }
  value
  delta
  deviceEUI
  timestamp
    }
   
  }
`
