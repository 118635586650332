import React, { FC } from 'react'
import DeviceStatus from './DeviceStatus'
import RawSamples from './RawSamples';
import { adminMenuUrls } from "./AdminMenuList";
import MeterCodePanel from './MeterCodePanel';
import MeterReadings from './MeterReadings';
import MeterValues from './MeterValues';
import NewMeterAndCustomer from './NewMeterAndCustomer';


type Props = {
    menuItem: string;
}

const AdminPanelWrapper: FC<Props> = ({ menuItem }) => {

    const adminMenuUrlList = adminMenuUrls();

    //These must be in the same order as AdminMenuList.ts
    switch (menuItem) {
        case adminMenuUrlList[0].toString():
            return <DeviceStatus />
        case adminMenuUrlList[1].toString():
            return <RawSamples />
        case adminMenuUrlList[2].toString():
            return <MeterCodePanel />
        case adminMenuUrlList[3].toString():
            return <MeterReadings />
        case adminMenuUrlList[4].toString():
            return <MeterValues />
        case adminMenuUrlList[5].toString():
            return <NewMeterAndCustomer />
        default:
            return <h2 className="text-xl font-bold text-primary">Not Found</h2>
    }
}

export default AdminPanelWrapper